import { ReactNode, useState } from "react";
import styles from "@/styles/deviceCard.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import IconComponent from "./shared/Icon";

export interface DeviceCardProps {
  cardTitle: string;
  cardAdditionalTitle?: string;
  deviceName: string;
  whiteDeviceImgSrc: string;
  blackDeviceImgSrc?: string;
  infoLink?: string;
  children?: ReactNode;
  className?: string;
}

type DeviceColorType = "white" | "black";

const DeviceCard = ({
  cardTitle,
  cardAdditionalTitle = "",
  whiteDeviceImgSrc,
  blackDeviceImgSrc = "",
  deviceName,
  children,
  infoLink = "",
  className = "",
}: DeviceCardProps) => {
  const [deviceColor, setDeviceColor] = useState<DeviceColorType>("white");

  const handleDeviceType = (type: DeviceColorType) => {
    setDeviceColor(type);
  };

  return (
    <li className={classNames(styles.deviceCardContainer, className)}>
      <div className={styles.cardTitle}>
        <h5>{cardTitle}</h5>
        {cardAdditionalTitle && <span>{cardAdditionalTitle}</span>}
      </div>
      <div className={styles.deviceContentContainer}>
        {blackDeviceImgSrc && (
          <ul className={styles.deviceTypeColorList}>
            <button
              className={styles.deviceTypeWhite}
              onClick={() => handleDeviceType("white")}
            ></button>
            <button
              className={styles.deviceTypeBlack}
              onClick={() => handleDeviceType("black")}
            ></button>
          </ul>
        )}
        <ul className={styles.deviceImgContainer}>
          <li
            className={classNames(
              styles.deviceImg,
              deviceColor === "white" && styles.active,
            )}
          >
            <img src={whiteDeviceImgSrc} alt="product" />
          </li>
          {blackDeviceImgSrc && (
            <li
              className={classNames(
                styles.deviceImg,
                deviceColor === "black" && styles.active,
              )}
            >
              <img src={blackDeviceImgSrc} alt="product" />
            </li>
          )}
        </ul>
      </div>
      <div className={styles.deviceTextBox}>
        {infoLink ? (
          <Link to={infoLink} className={styles.titleContainer}>
            <h5>{deviceName}</h5>
            <em className={styles.plusIcon}>
              <IconComponent iconName="icon_plus_circle_blue" iconAlt="icon" />
            </em>
          </Link>
        ) : (
          <div className={styles.titleContainer}>
            <h5>{deviceName}</h5>
          </div>
        )}
        <div className={styles.descriptionContainer}>{children}</div>
      </div>
    </li>
  );
};

export default DeviceCard;
