import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";

// Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import styles from "@/styles/swiper.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";

export interface SwiperComponentProps {
  showNavigation?: boolean;
  showScrollbar?: boolean;
  showPagination?: boolean;
  className?: string;
  swiperItems: ReactNode[];
  isAutoPlay?: boolean;
  autoPlayDelay?: number;
  swiperKey: string;
  swiperSlideClassName?: string;
}

export const SwiperComponent: React.FC<SwiperComponentProps> = ({
  showNavigation = false,
  showScrollbar = false,
  showPagination = false,
  className,
  swiperItems,
  isAutoPlay = false,
  autoPlayDelay = 3000,
  swiperKey,
  swiperSlideClassName = "",
}) => {
  return (
    <Swiper
      key={swiperKey}
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
      navigation={showNavigation}
      scrollbar={showScrollbar ? { draggable: true } : false}
      pagination={showPagination ? { clickable: true } : false}
      autoplay={isAutoPlay ? { delay: autoPlayDelay } : false}
      slidesPerView="auto"
      spaceBetween={30}
      className={classNames(styles.swiperContainer, className)}
      // centeredSlides={true}
    >
      {swiperItems.map((item, index) => (
        <SwiperSlide
          key={index}
          className={classNames(styles.swiperSlide, swiperSlideClassName)}
        >
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
