import { Dispatch, ReactNode, SetStateAction } from "react";
import styles from "@/styles/mobileAsideNav.module.scss";
import classNames from "classnames";

export interface MobileAsideNavProps {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}

const MobileAsideNav = ({
  isOpen,
  setIsOpen,
  children,
}: MobileAsideNavProps) => {
  return (
    <div>
      <div
        className={classNames(!isOpen ? styles.hideDimmed : styles.dimmed)}
        onClick={() => setIsOpen?.(false)}
      ></div>

      <>{children}</>
    </div>
  );
};

export default MobileAsideNav;
