import { Dispatch, ReactNode, SetStateAction } from "react";
import classNames from "classnames";
import styles from "@/styles/modal.module.scss";
import { useTranslation } from "react-i18next";
export interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  children: ReactNode;
}

const Modal = ({ isOpen, setIsOpen, title, children }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.modalFixedPopWrapper, styles.layerPopup)}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h1>{title}</h1>
          <button
            title={t("POPUP_CLOSE")}
            className={styles.modalCloseBtn}
            onClick={() => setIsOpen(false)}
          >
            X
          </button>
        </div>
        <div className={styles.modalInnerContainer}>
          <div className={styles.modalContent}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
