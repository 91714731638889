import { MouseEvent, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styles from "@/styles/home.module.scss";
import layoutStyles from "@/styles/commonLayout.module.scss";

import useIntersectionStore, { InterSectionType } from "@/store/intersection";
import useViewportStore from "@/store/viewport";
import useLanguageStore from "@/store/language";

import useIntersectionObserver from "@/hooks/useIntersectionObserver";

import BannerSwiperComponent from "@/components/BannerSwiper";
import AnimatedTypography from "@/components/AnimatedTypography";
import IconComponent from "@/components/shared/Icon";
import DeviceCard from "@/components/DeviceCard";
import Modal from "@/components/shared/Modal";
import SwiperComponent from "@/components/Swiper";
import ImageComponent from "@/components/shared/Image";
import AnimatedImage from "@/components/AnimatedImage";

import { scrollToTarget } from "@/utils/scroll";
import { mainImage, jpMainImage } from "@/utils/image";
import {
  FADE_LEFT_3D,
  FADE_NONE_3D,
  FADE_RIGHT_3D,
} from "@/constants/fadeAnimation3d";

const HomePage = () => {
  const { isMobile, viewPortHeight } = useViewportStore((state) => state);
  const { isLanguageKr } = useLanguageStore((state) => state);
  const [planIndex, setPlanIndex] = useState(0);
  const [isCloudModalOpen, setIsCloudModalOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const { swiperContainerRef, setRef, setMainInterSection } =
    useIntersectionStore((state) => state);

  const { ref: swiperRef, isIntersecting: isSwiperContainerVisible } =
    useIntersectionObserver();
  const { ref: toastcamSectionRef, isIntersecting: isToastcamVisible } =
    useIntersectionObserver();
  const { ref: serviceSectionRef, isIntersecting: isServiceVisible } =
    useIntersectionObserver();
  const { ref: deviceSectionRef, isIntersecting: isDeviceVisible } =
    useIntersectionObserver();
  const { ref: costPlainSectionRef, isIntersecting: isCostPlainVisible } =
    useIntersectionObserver();
  const {
    ref: additionalServiceSectionRef,
    isIntersecting: isAdditionalServiceVisible,
  } = useIntersectionObserver();
  const { ref: inquirySectionRef, isIntersecting: isInquiryVisible } =
    useIntersectionObserver();

  useEffect(() => {
    setRef("swiperContainerRef", swiperRef);
    setRef("toastcamRef", toastcamSectionRef);
    setRef("serviceRef", serviceSectionRef);
    setRef("deviceRef", deviceSectionRef);
    setRef("costPlainRef", costPlainSectionRef);
    setRef("additionalServiceRef", additionalServiceSectionRef);
    setRef("inquiryRef", inquirySectionRef);
  }, [
    swiperRef,
    toastcamSectionRef,
    serviceSectionRef,
    deviceSectionRef,
    costPlainSectionRef,
    additionalServiceSectionRef,
    inquirySectionRef,
    setRef,
  ]);

  //슬라이더 텍스트 ref
  const slide1TitleTextRef = useRef<HTMLParagraphElement>(null);
  const slide1DescTextRef = useRef<HTMLParagraphElement>(null);
  const slide2TitleTextRef = useRef<HTMLParagraphElement>(null);
  const slide2DescTextRef = useRef<HTMLParagraphElement>(null);

  const h265DeviceRef = useRef<HTMLDivElement>(null);
  const h264DeviceRef = useRef<HTMLDivElement>(null);
  const v4Ref = useRef<HTMLDivElement>(null);

  const PLANS = [
    {
      id: 0,
      name: t("7DAY_PLAN"),
      price: "1,265",
      recordDuration: "7",
      animationDelay: 100,
    },
    {
      id: 1,
      name: t("14DAY_PLAN"),
      price: "1,650",
      recordDuration: "14",
      animationDelay: 250,
    },
    {
      id: 2,
      name: t("30DAY_PLAN"),
      price: "2,145",
      recordDuration: "30",
      animationDelay: 400,
    },
  ];

  const handleGatewayGuideLink = () => {
    if (isMobile) {
      alert(t("MOBILE_DISABLED_ALERT"));
      return;
    }
    window.open(
      import.meta.env.VITE_GATEWAY_GUID_URL,
      "_blank",
      "width=600,height=620,top=50,left=50,scrollbars=no",
    );
  };

  const handleLinkPartner = (e: MouseEvent) => {
    if (isMobile) {
      e.preventDefault();
      alert(t("MOBILE_DISABLED_ALERT"));
      return;
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setPlanIndex((prevIndex) => (prevIndex + 1) % PLANS.length);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [PLANS.length]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const target = queryParams.get("target");
    const isInterSectionType = (value: any): value is InterSectionType => {
      return [
        "toastcam",
        "service",
        "device",
        "costPlain",
        "additionalService",
        "inquiry",
        "",
      ].includes(value);
    };

    if (isInterSectionType(target)) {
      switch (target) {
        case "toastcam":
          scrollToTarget(toastcamSectionRef);
          break;
        case "service":
          scrollToTarget(serviceSectionRef);
          break;
        case "device":
          scrollToTarget(deviceSectionRef);
          break;
        case "costPlain":
          scrollToTarget(costPlainSectionRef);
          break;
        case "additionalService":
          scrollToTarget(additionalServiceSectionRef);
          break;
        case "inquiry":
          scrollToTarget(inquirySectionRef);
          break;
        default:
          break;
      }
      return;
    }

    if (target === "h265" && h265DeviceRef.current) {
      h265DeviceRef.current.scrollIntoView({
        block: "center",
      });
      return;
    }
    if (target === "h264" && h264DeviceRef.current) {
      h264DeviceRef.current.scrollIntoView({
        block: "center",
      });
      return;
    }

    if (target === "v4" && v4Ref.current) {
      v4Ref.current.scrollIntoView({
        block: "center",
      });
    }
  }, [
    location,
    toastcamSectionRef,
    serviceSectionRef,
    deviceSectionRef,
    costPlainSectionRef,
    additionalServiceSectionRef,
    inquirySectionRef,
  ]);

  const retriggerTextAnimation = () => {
    const refs = [
      slide1TitleTextRef,
      slide1DescTextRef,
      slide2TitleTextRef,
      slide2DescTextRef,
    ];
    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.style.animation = "none";
        setTimeout(() => {
          if (ref.current) ref.current.style.animation = "";
        }, 10);
      }
    });
  };

  useEffect(() => {
    if (isSwiperContainerVisible) {
      setMainInterSection("");
      return;
    }

    if (isToastcamVisible) {
      setMainInterSection("toastcam");
      return;
    }
    if (isServiceVisible) {
      setMainInterSection("service");
      return;
    }
    if (isDeviceVisible) {
      setMainInterSection("device");
      return;
    }
    if (isCostPlainVisible) {
      setMainInterSection("costPlain");
      return;
    }
    if (isAdditionalServiceVisible) {
      setMainInterSection("additionalService");
      return;
    }
    if (isInquiryVisible) {
      setMainInterSection("inquiry");
      return;
    }
  }, [
    isSwiperContainerVisible,
    isToastcamVisible,
    isServiceVisible,
    isDeviceVisible,
    isCostPlainVisible,
    isAdditionalServiceVisible,
    isInquiryVisible,
    setMainInterSection,
  ]);

  return (
    <div id={styles.homeContainer} role="toolbar">
      <div className={styles.mainSwiperContainer}>
        <BannerSwiperComponent
          swiperKey="main"
          autoPlayDelay={5000}
          useFadeEffect={true}
          handleSlidePrevTransitionEnd={retriggerTextAnimation}
          handleSlideNextTransitionEnd={retriggerTextAnimation}
          swiperItems={[
            <section
              ref={swiperContainerRef}
              className={styles.mainVisual}
              style={{ height: `${viewPortHeight}px` }}
            >
              <div
                className={classNames(styles.slideImage, styles.slideImage1)}
              ></div>
              <div className={styles.slideTextContainer}>
                <p className={styles.slideText1} ref={slide1TitleTextRef}>
                  {t("MAIN_SLIDE1_TITLE1")}
                  <br className={layoutStyles.moBr}></br>
                  {t("MAIN_SLIDE1_TITLE2")}
                </p>
                <p className={styles.slideText2} ref={slide1DescTextRef}>
                  {t("MAIN_SUB_STRING_FRAGMENT")}
                  <br></br>
                  {t("MAIN_SUB_STRING_FRAGMENT2")}
                  <br className={styles.mobileBr}></br>
                  {t("MAIN_SUB_STRING_FRAGMENT3")}
                </p>
              </div>
            </section>,
            <section
              style={{ height: `${viewPortHeight}px` }}
              ref={swiperContainerRef}
              className={styles.mainVisual}
            >
              <div
                className={classNames(styles.slideImage, styles.slideImage2)}
              ></div>
              <div className={styles.slideTextContainer}>
                <p className={styles.slideText1} ref={slide2TitleTextRef}>
                  {t("MAIN_SLIDE2_TITLE1")}
                  <br className={layoutStyles.moBr}></br>
                  {t("MAIN_SLIDE2_TITLE2")}
                </p>
                <p className={styles.slideText2} ref={slide2DescTextRef}>
                  {t("MAIN_SUB_STRING2_FRAGMENT")}
                  <br></br>
                  {t("MAIN_SUB_STRING2_FRAGMENT2")}
                </p>
              </div>
            </section>,
          ]}
        ></BannerSwiperComponent>

        <div className={styles.scrollIconContainer}>
          <span>
            <em />
          </span>
        </div>
      </div>

      <section id="mainContent">
        <article id={styles.mainFutureCon} ref={toastcamSectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_TOAST_CAM_FRAGMENT")}
                  <br className={layoutStyles.moBr}></br>
                  <b>{t("SECTION_TITLE_TOAST_CAM_FRAGMENT2")}</b>
                </h3>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubTitle}>
                  {t("SECTION_SUB_TITLE_TOAST_CAM")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText}>
                  {t("SECTION_SUB_STRING_TOAST_CAM")}
                </p>
              </AnimatedTypography>
            </div>
            <div className={styles.mainFutureBox}>
              <AnimatedTypography>
                <ImageComponent
                  className={styles.shadowImg}
                  src={mainImage.krMainFuture01}
                  moSrc={mainImage.krMainFutureM01}
                  jpSrc={jpMainImage.jpMainFuture01}
                  jpMoSrc={jpMainImage.jpMainFutureM01}
                />
              </AnimatedTypography>
              <AnimatedTypography>
                <ImageComponent
                  className={styles.shadowImg}
                  src={mainImage.krMainFuture02}
                  moSrc={mainImage.krMainFutureM02}
                  jpSrc={jpMainImage.jpMainFuture02}
                  jpMoSrc={jpMainImage.jpMainFutureM02}
                />
              </AnimatedTypography>
              <AnimatedTypography>
                <img
                  className={styles.shadowImg}
                  src={
                    isMobile
                      ? mainImage.krMainFutureM03
                      : mainImage.krMainFuture03
                  }
                  alt="도식화 이미지3"
                />
              </AnimatedTypography>
            </div>
          </div>
        </article>

        <article id={styles.mainGraphCon} ref={h265DeviceRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_H265_FRAGMENT")}
                  <br className={layoutStyles.moBr}></br>
                  <b>{t("SECTION_TITLE_H265_FRAGMENT2")}</b>
                  {t("SECTION_TITLE_H265_FRAGMENT3")}
                </h3>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubTitle}>
                  {t("SECTION_SUB_TITLE_H265")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText}>
                  {t("SECTION_SUB_STRING_H265_FRAGMENT")}
                  <br />
                  {t("SECTION_SUB_STRING_H265_FRAGMENT2")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <div className={styles.mainBtnContainer} data-scroll="fade-up">
                  <Link to="/h265" className={styles.mainLinkH265}>
                    {t("LEARN_ABOUT_H265_WEB_SERVICE")}
                    <em className={styles.h265Icon}>
                      <IconComponent
                        className={styles.blueIcon}
                        iconName="arrow_right_blue"
                        iconAlt="icon"
                      />
                      <IconComponent
                        className={styles.whiteIcon}
                        iconName="arrow_right_white"
                        iconAlt="icon"
                      />
                    </em>
                  </Link>
                </div>
              </AnimatedTypography>
            </div>
            <div className={styles.mainGraphContainer}>
              <AnimatedTypography className={styles.graphImgContainer}>
                <ImageComponent
                  src={mainImage.mainGraphPC}
                  moSrc={mainImage.mainGraphPC}
                  jpSrc={mainImage.mainGraphPC}
                  jpMoSrc={mainImage.mainGraphPC}
                />
                <p className={styles.mainGraphTxt}>{t("COMMENT_BITRATE")}</p>
              </AnimatedTypography>
            </div>
          </div>
        </article>

        <article id={styles.mainCloudCon} ref={serviceSectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_COST_FRAGMENT")}
                  <b>{t("SECTION_TITLE_COST_FRAGMENT2")}</b>
                  {t("SECTION_TITLE_COST_FRAGMENT3")}
                  <br className={layoutStyles.pcBr} />
                  {t("SECTION_TITLE_COST_FRAGMENT4")}
                </h3>
              </AnimatedTypography>
            </div>
            <ul className={styles.mainCloudList}>
              <li className={styles.mainCloudItem}>
                <div className={styles.mainCloudImg}>
                  <AnimatedTypography
                    initialTranslate3d={FADE_LEFT_3D}
                    animationDelay={150}
                  >
                    <ImageComponent
                      className={styles.mainCloudImg01}
                      src={mainImage.krMainCloudInstall01}
                      moSrc={mainImage.krMainCloudInstallM01}
                      jpSrc={jpMainImage.jpMainCloudInstall01}
                      jpMoSrc={jpMainImage.jpMainCloudInstall01Mo}
                      alt="image"
                    />
                  </AnimatedTypography>

                  <AnimatedTypography initialTranslate3d={FADE_LEFT_3D}>
                    <ImageComponent
                      className={styles.mainCloudImg01}
                      src={mainImage.krMainCloudInstall02}
                      moSrc={mainImage.krMainCloudInstallM02}
                      jpSrc={jpMainImage.jpMainCloudInstall02}
                      jpMoSrc={jpMainImage.jpMainCloudInstall02Mo}
                      alt="image"
                    />
                  </AnimatedTypography>
                </div>
                <div className={styles.mainCloudBoxM}>
                  <AnimatedTypography animationDelay={350}>
                    <ImageComponent
                      className={styles.mainCloudImg01}
                      src={mainImage.krMainCloudInstallM01}
                      jpSrc={jpMainImage.jpMainCloudInstall01Mo}
                      moSrc={mainImage.krMainCloudInstallM01}
                      jpMoSrc={jpMainImage.jpMainCloudInstall01Mo}
                    />
                  </AnimatedTypography>

                  <AnimatedTypography>
                    <ImageComponent
                      className={styles.mainCloudImg01}
                      src={mainImage.krMainCloudInstallM02}
                      jpSrc={jpMainImage.jpMainCloudInstall02Mo}
                      moSrc={mainImage.krMainCloudInstallM02}
                      jpMoSrc={jpMainImage.jpMainCloudInstall02Mo}
                    />
                  </AnimatedTypography>
                </div>
                <div className={styles.mainCloudText}>
                  <span className={styles.point}>
                    <em className={styles.icon}>
                      <IconComponent
                        iconName="icon_check_circle_white"
                        iconAlt="icon"
                      />
                    </em>
                    Point 01
                  </span>
                  <h5 className={styles.tit}>{t("SECTION_SUB_TITLE1_COST")}</h5>
                  <p className={styles.txt}>
                    {t("SECTION_SUB_STRING1_COST_FRAGMENT")}
                    <br className={layoutStyles.pcBr} />
                    {t("SECTION_SUB_STRING1_COST_FRAGMENT2")}
                  </p>
                </div>
              </li>
              <li className={styles.mainCloudItem}>
                <div className={styles.mainCloud2Img}>
                  <div className={styles.mainCloudImg02Box}>
                    <AnimatedTypography
                      initialTranslate3d={FADE_NONE_3D}
                      animationDelay={550}
                      className={styles.mainCloudImg02}
                    >
                      <ImageComponent
                        className={classNames(styles.mainCloudOptionImg01)}
                        src={mainImage.krMainCloudOption01}
                        jpSrc={jpMainImage.jpMainCloudOption01}
                        alt="image"
                      />
                    </AnimatedTypography>
                    <AnimatedTypography
                      initialTranslate3d={FADE_NONE_3D}
                      animationDelay={250}
                      className={styles.mainCloudImg02}
                    >
                      <ImageComponent
                        className={classNames(styles.mainCloudOptionImg02)}
                        src={mainImage.krMainCloudOption02}
                        jpSrc={jpMainImage.jpMainCloudOption02}
                        alt="image"
                      />
                    </AnimatedTypography>
                    <AnimatedTypography
                      initialTranslate3d={FADE_NONE_3D}
                      animationDelay={400}
                      className={styles.mainCloudImg02}
                    >
                      <ImageComponent
                        className={classNames(styles.mainCloudOptionImg03)}
                        src={mainImage.krMainCloudOption03}
                        jpSrc={jpMainImage.jpMainCloudOption03}
                        alt="image"
                      />
                    </AnimatedTypography>
                    <AnimatedTypography
                      initialTranslate3d={FADE_NONE_3D}
                      animationDelay={700}
                      className={styles.mainCloudImg02}
                    >
                      <ImageComponent
                        className={classNames(styles.mainCloudOptionImg04)}
                        src={mainImage.krMainCloudOption04}
                        jpSrc={jpMainImage.jpMainCloudOption04}
                        alt="image"
                      />
                    </AnimatedTypography>

                    <AnimatedTypography
                      initialTranslate3d={FADE_NONE_3D}
                      animationDelay={100}
                      className={styles.mainCloudImg02}
                    >
                      <ImageComponent
                        className={styles.mainCloudOptionImgCenter}
                        src={mainImage.krMainCloudOptionCenter}
                        jpSrc={jpMainImage.jpMainCloudOptionCenter}
                        alt="image"
                      />
                    </AnimatedTypography>
                  </div>
                </div>
                <div className={styles.mainCloudText}>
                  <span className={styles.point}>
                    <em className={styles.icon}>
                      <IconComponent
                        iconName="icon_check_circle_white"
                        iconAlt="icon"
                      />
                    </em>
                    Point 02
                  </span>
                  <h5 className={styles.tit}>{t("SECTION_SUB_TITLE2_COST")}</h5>
                  <p className={styles.txt}>
                    {t("SECTION_SUB_STRING2_COST_FRAGMENT")}
                    <br className={layoutStyles.pcBr} />
                    {t("SECTION_SUB_STRING2_COST_FRAGMENT2")}
                  </p>
                  <p className={styles.txt}>
                    {t("SECTION_SUB_STRING3_COST_FRAGMENT")}
                  </p>
                  <p className={styles.txt}>{t("SECTION_SUB_STRING4_COST")}</p>
                </div>
              </li>
            </ul>
          </div>
        </article>

        <article id={styles.mainCheckCon}>
          <div className={layoutStyles.areaBox}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  <b>{t("SECTION_TITLE_VIDEO_FRAGMENT")}</b>
                  <br className={layoutStyles.moBr}></br>
                  {t("SECTION_TITLE_VIDEO_FRAGMENT2")}
                </h3>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubTitle}>
                  {t("SECTION_SUB_TITLE1_VIDEO_FRAGMENT")}
                  <br className={layoutStyles.pcBr} />
                  {t("SECTION_SUB_TITLE1_VIDEO_FRAGMENT2")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText}>
                  {t("SECTION_SUB_TITLE2_VIDEO")}
                </p>
              </AnimatedTypography>
            </div>
            <div className={styles.mainCheckWrapper}>
              <AnimatedTypography className={styles.mainCheckImgCon}>
                <div className={styles.mainCheckImgPc}>
                  <img
                    src={
                      isLanguageKr
                        ? mainImage.mainCheckPcKr
                        : jpMainImage.jpMainCheckPc
                    }
                    alt="pc_image"
                  />
                  <span className={styles.pcBg}></span>
                </div>
                <div className={styles.mainCheckImgMo}>
                  <img
                    src={
                      isLanguageKr
                        ? mainImage.mainCheckMoKr
                        : jpMainImage.jpMainCheckMo
                    }
                    alt="mo_image"
                  />
                  <span className={styles.moBg}></span>
                </div>
              </AnimatedTypography>
              <ul className={styles.mainCheckList}>
                <AnimatedTypography tag="li" className={styles.mainCheckItem}>
                  <div className={styles.mainCheckIcon}>
                    <img src={mainImage.mainCheckIcon01} alt="아이콘" />
                  </div>
                  <div className={styles.mainCheckTxt}>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM1_TITLE_VIDEO")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM1_DESCRIBE_VIDEO")}
                    </p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  animationDelay={100}
                  className={styles.mainCheckItem}
                >
                  <div className={styles.mainCheckIcon}>
                    <img src={mainImage.mainCheckIcon02} alt="아이콘" />
                  </div>
                  <div className={styles.mainCheckTxt}>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM2_TITLE_VIDEO")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM2_DESCRIBE_VIDEO")}
                      <br className={layoutStyles.pcBr}></br>
                      {t("SECTION_NAV_ITEM2_DESCRIBE_VIDEO2")}
                    </p>
                  </div>
                </AnimatedTypography>

                <AnimatedTypography
                  tag="li"
                  className={styles.mainCheckItem}
                  animationDelay={250}
                >
                  <div className={styles.mainCheckIcon}>
                    <img src={mainImage.mainCheckIcon03} alt="아이콘" />
                  </div>
                  <div className={styles.mainCheckTxt}>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM3_TITLE_VIDEO")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM3_DESCRIBE_VIDEO")}
                    </p>
                  </div>
                </AnimatedTypography>

                <AnimatedTypography
                  tag="li"
                  className={styles.mainCheckItem}
                  animationDelay={400}
                >
                  <div className={styles.mainCheckIcon}>
                    <img src={mainImage.mainCheckIcon04} alt="아이콘" />
                  </div>
                  <div className={styles.mainCheckTxt}>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM4_TITLE_VIDEO")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM4_DESCRIBE_VIDEO")}
                    </p>
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
          </div>
        </article>

        <article id={styles.mainMonitoringCon}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle} data-scroll="fade-up">
                  {t("SECTION_TITLE_MONITOR_FRAGMENT")}
                  <br className={layoutStyles.moBr}></br>
                  <b>{t("SECTION_TITLE_MONITOR_FRAGMENT2")}</b>
                </h3>
              </AnimatedTypography>

              <AnimatedTypography>
                <p className={styles.mainSubTitle} data-scroll="fade-up">
                  {t("SECTION_SUB_TITLE_MONITOR")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText} data-scroll="fade-up">
                  {t("SECTION_SUB_STRING_MONITOR")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <div className={styles.mainBtnContainer}>
                  {isMobile ? (
                    <div>{t("CLOUD_DEMO_MOBILE_DISABLED")}</div>
                  ) : (
                    <button
                      className={styles.mainLinkMonitoringDemo}
                      onClick={() => setIsCloudModalOpen(true)}
                    >
                      {t("SHOW_CLOUD_MONITOR_DEMO")}
                      <em className={styles.monitoringDemoIcon}>
                        <IconComponent
                          className={styles.blueIcon}
                          iconName="arrow_right_blue"
                          iconAlt="icon"
                        />
                        <IconComponent
                          className={styles.whiteIcon}
                          iconName="arrow_right_white"
                          iconAlt="icon"
                        />
                      </em>
                    </button>
                  )}
                </div>
              </AnimatedTypography>
            </div>
            {isCloudModalOpen && (
              <Modal
                isOpen={isCloudModalOpen}
                setIsOpen={setIsCloudModalOpen}
                title={t("CLOUD_MONITOR_DEMO")}
              >
                <video
                  controls
                  controlsList="nodownload"
                  autoPlay
                  loop
                  poster=""
                  className="video_cont"
                >
                  <source
                    src={
                      isLanguageKr
                        ? "https://bizcam.toast.com/CF/cloud_monitor_demo_kr.mp4"
                        : "https://bizcam.toast.com/CF/cloud_monitor_demo_jp.mp4"
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </Modal>
            )}
            <div className={styles.mainMonitoringImg}>
              <img src={mainImage.mainMonitoring} alt="모니터 이미지" />
            </div>
            <div className={styles.mainMonitoringWrapper}>
              <ul className={styles.mainMonitoringList}>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainMonitoringItem}
                  animationDelay={100}
                >
                  <div className={styles.mainMonitoringIcon}>
                    <img src={mainImage.mainMonitoringIcon01} alt="아이콘" />
                  </div>
                  <div className={styles.mainMonitoringTxt}>
                    <h5>{t("SECTION_NAV_ITEM1_TITLE_MONITOR")}</h5>
                    <p>{t("SECTION_NAV_ITEM1_DESCRIBE_MONITOR_FRAGMENT")}</p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainMonitoringItem}
                  animationDelay={250}
                >
                  <div className={styles.mainMonitoringIcon}>
                    <img src={mainImage.mainMonitoringIcon02} alt="아이콘" />
                  </div>
                  <div className={styles.mainMonitoringTxt}>
                    <h5>{t("SECTION_NAV_ITEM2_TITLE_MONITOR")}</h5>
                    <p>{t("SECTION_NAV_ITEM2_DESCRIBE_MONITOR_FRAGMENT")}</p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainMonitoringItem}
                  animationDelay={400}
                >
                  <div className={styles.mainMonitoringIcon}>
                    <img src={mainImage.mainMonitoringIcon03} alt="아이콘" />
                  </div>
                  <div className={styles.mainMonitoringTxt}>
                    <h5>{t("SECTION_NAV_ITEM3_TITLE_MONITOR")}</h5>
                    <p>{t("SECTION_NAV_ITEM3_DESCRIBE_MONITOR")}</p>
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
          </div>
        </article>

        <article id={styles.mainSecurityCon}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <h3 className={styles.mainTitle}>
                {t("SECTION_TITLE_SECURITY_FRAGMENT")} <br />
                {t("SECTION_TITLE_SECURITY_FRAGMENT2")}
                <b>{t("SECTION_TITLE_SECURITY_FRAGMENT3")}</b>
                {t("SECTION_TITLE_SECURITY_FRAGMENT4")}
              </h3>
            </div>
            <div className={styles.mainSecurityWrapper}>
              <ul className={styles.mainSecurityList}>
                <li className={styles.mainSecurityItem}>
                  <div
                    className={classNames(
                      styles.mainSecurityImg,
                      styles.mainSecurityImgPadding,
                    )}
                  >
                    <div className={styles.mainSecurityItemInner}>
                      <figure className={styles.mainSecurity01Img01}>
                        <AnimatedImage
                          src={mainImage.securityPoint01Img01}
                          moSrc={mainImage.securityPoint01MoImg01}
                          jpSrc={jpMainImage.jpSecurityPoint01Img01}
                          jpMoSrc={jpMainImage.jpSecurityPoint01MoImg01}
                          alt=""
                        />
                      </figure>
                      <figure className={styles.mainSecurity01Img02}>
                        <AnimatedImage
                          src={mainImage.securityPoint01Img02}
                          moSrc={mainImage.securityPoint01MoImg02}
                          jpSrc={jpMainImage.jpSecurityPoint01Img02}
                          jpMoSrc={jpMainImage.jpSecurityPoint01MoImg02}
                          animationDelay={200}
                          alt=""
                        />
                      </figure>
                      <figure className={styles.mainSecurity01Img03}>
                        <AnimatedImage
                          src={mainImage.securityPoint01Img03}
                          moSrc={mainImage.securityPoint01MoImg03}
                          jpSrc={jpMainImage.jpSecurityPoint01Img03}
                          jpMoSrc={jpMainImage.jpSecurityPoint01MoImg03}
                          animationDelay={400}
                          alt=""
                        />
                      </figure>
                      <figure className={styles.mainSecurity01Img04}>
                        <AnimatedImage
                          src={mainImage.securityPoint01Img04}
                          moSrc={mainImage.securityPoint01MoImg04}
                          jpSrc={jpMainImage.jpSecurityPoint01Img04}
                          jpMoSrc={jpMainImage.jpSecurityPoint01MoImg04}
                          animationDelay={600}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  <AnimatedTypography
                    className={styles.mainSecurityTxt}
                    initialTranslate3d={FADE_LEFT_3D}
                  >
                    <span className={styles.point}>
                      <em className={styles.icon}>
                        <IconComponent
                          iconName="icon_check_circle_white"
                          iconAlt="icon"
                        />
                      </em>
                      Point 01
                    </span>
                    <h5 className={styles.tit}>
                      {t("SECTION_SUB_TITLE1_SECURITY")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_SUB_STRING1_SECURITY_FRAGMENT")}
                      <br className={layoutStyles.pcBr} />
                      {t("SECTION_SUB_STRING1_SECURITY_FRAGMENT2")}
                    </p>
                  </AnimatedTypography>
                </li>
                <li className={styles.mainSecurityItem}>
                  <div
                    className={classNames(
                      styles.mainSecurityImg,
                      styles.mainSecurityImgPadding,
                    )}
                  >
                    <div className={styles.mainSecurityItemInner}>
                      <figure className={styles.mainSecurity02Img01}>
                        <AnimatedImage
                          src={mainImage.securityPoint02Img01}
                          moSrc={mainImage.securityPoint02MoImg01}
                          jpSrc={jpMainImage.jpSecurityPoint02Img01}
                          jpMoSrc={jpMainImage.jpSecurityPoint02MoImg01}
                          alt=""
                        />
                      </figure>
                      <figure className={styles.mainSecurity02Img02}>
                        <AnimatedImage
                          src={mainImage.securityPoint02Img02}
                          moSrc={mainImage.securityPoint02MoImg02}
                          jpSrc={jpMainImage.jpSecurityPoint02Img02}
                          jpMoSrc={jpMainImage.jpSecurityPoint02MoImg02}
                          animationDelay={200}
                          alt=""
                        />
                      </figure>
                      <figure className={styles.mainSecurity02Img03}>
                        <AnimatedImage
                          src={mainImage.securityPoint02Img03}
                          moSrc={mainImage.securityPoint02MoImg03}
                          jpSrc={jpMainImage.jpSecurityPoint02Img03}
                          jpMoSrc={jpMainImage.jpSecurityPoint02MoImg03}
                          animationDelay={400}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  <AnimatedTypography
                    className={styles.mainSecurityTxt}
                    initialTranslate3d={FADE_RIGHT_3D}
                  >
                    <span className={styles.point}>
                      <em className={styles.icon}>
                        <IconComponent
                          iconName="icon_check_circle_white"
                          iconAlt="icon"
                        />
                      </em>
                      Point 02
                    </span>
                    <h5 className={styles.tit}>
                      {t("SECTION_SUB_TITLE2_SECURITY")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_SUB_STRING2_SECURITY_FRAGMENT")}
                      <br className={layoutStyles.pcBr} />
                      {t("SECTION_SUB_STRING2_SECURITY_FRAGMENT2")}
                    </p>
                  </AnimatedTypography>
                </li>
                <li className={styles.mainSecurityItem}>
                  <AnimatedTypography
                    className={styles.mainSecurityImg}
                    initialTranslate3d={FADE_RIGHT_3D}
                  >
                    <span className={styles.bgImage3}></span>
                  </AnimatedTypography>
                  <AnimatedTypography
                    className={styles.mainSecurityTxt}
                    initialTranslate3d={FADE_LEFT_3D}
                  >
                    <span className={styles.point}>
                      <em className={styles.icon}>
                        <IconComponent
                          iconName="icon_check_circle_white"
                          iconAlt="icon"
                        />
                      </em>
                      Point 03
                    </span>
                    <h5 className={styles.tit}>
                      {t("SECTION_SUB_TITLE3_SECURITY")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_SUB_STRING3_SECURITY_FRAGMENT")}
                      <br className={layoutStyles.pcBr} />
                      {t("SECTION_SUB_STRING3_SECURITY_FRAGMENT2")}
                      <br className={layoutStyles.pcBr} />
                      {t("SECTION_SUB_STRING3_SECURITY_FRAGMENT3")}
                    </p>
                  </AnimatedTypography>
                </li>
              </ul>
            </div>
          </div>
        </article>

        <article id={styles.mainInstallCon}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  <b>{t("EASY_INSTALLATION")}</b>
                  {t("EASY_MAINTENANCE")}
                </h3>
              </AnimatedTypography>

              <AnimatedTypography>
                <p className={styles.mainSubTitle}>
                  {t("SECTION_SUB_TITLE_SETTING")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText}>
                  {t("SECTION_SUB_STRING_SETTING")}
                </p>
              </AnimatedTypography>
            </div>
            <div className={styles.mainInstallWrapper}>
              <ul className={styles.mainInstallList}>
                <AnimatedTypography tag="li" className={styles.mainInstallItem}>
                  <div className={styles.mainInstallIcon}>
                    <img src={mainImage.mainInstallIcon01} alt="아이콘" />
                  </div>
                  <div className={styles.mainInstallTxt}>
                    <span className={styles.num}>01</span>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM1_TITLE_SETTING")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM1_DESCRIBE_SETTING")}
                    </p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={`${styles.mainInstallItem} ${styles.item02}`}
                  animationDelay={150}
                >
                  <div className={styles.mainInstallIcon}>
                    <img src={mainImage.mainInstallIcon02} alt="아이콘" />
                  </div>
                  <div className={styles.mainInstallTxt}>
                    <span className={styles.num}>02</span>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM2_TITLE_SETTING")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM2_DESCRIBE_SETTING")}
                    </p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={`${styles.mainInstallItem} ${styles.item03}`}
                  animationDelay={300}
                >
                  <div className={styles.mainInstallIcon}>
                    <img src={mainImage.mainInstallIcon03} alt="아이콘" />
                  </div>
                  <div className={styles.mainInstallTxt}>
                    <span className={styles.num}>03</span>
                    <h5 className={styles.tit}>
                      {t("SECTION_NAV_ITEM3_TITLE_SETTING")}
                    </h5>
                    <p className={styles.txt}>
                      {t("SECTION_NAV_ITEM3_DESCRIBE_SETTING")}
                    </p>
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
          </div>
        </article>

        <article id={styles.mainRecyclingCon}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_RECYCLE_FRAGMENT")}
                  <b>{t("SECTION_TITLE_RECYCLE_FRAGMENT2")}</b>
                </h3>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubTitle}>
                  {t("SECTION_SUB_TITLE_RECYCLE")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText}>
                  {t("SECTION_SUB_STRING_RECYCLE_FRAGMENT")}
                  <br className={layoutStyles.pcBr} />
                  {t("SECTION_SUB_STRING_RECYCLE_FRAGMENT2")}
                </p>
              </AnimatedTypography>
            </div>
            <div className={styles.mainRecyclingBox}>
              <ul className={styles.mainRecyclingList}>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                >
                  <div className={styles.mainRecyclingImg}>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg01}
                      jpSrc={jpMainImage.jpMainRecyclingImg01}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                  animationDelay={150}
                >
                  <div className={styles.mainRecyclingCenterImg}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg02}
                      jpSrc={jpMainImage.jpMainRecyclingImg02}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                  animationDelay={300}
                >
                  <div className={styles.mainRecyclingImg}>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg03}
                      jpSrc={jpMainImage.jpMainRecyclingImg03}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
            <div className={styles.mainRecyclingBoxM}>
              <ul className={styles.mainRecyclingList}>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                >
                  <div className={styles.mainRecyclingImg}>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg01M}
                      jpSrc={jpMainImage.jpMainRecyclingImg01Mo}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                  animationDelay={150}
                >
                  <div className={styles.mainRecyclingCenterImg}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg02M}
                      jpSrc={jpMainImage.jpMainRecyclingImg02Mo}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainRecyclingItem}
                  animationDelay={300}
                >
                  <div className={styles.mainRecyclingImg}>
                    <ImageComponent
                      src={mainImage.krMainRecyclingImg03M}
                      jpSrc={jpMainImage.jpMainRecyclingImg03Mo}
                      alt="image"
                    />
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
            <AnimatedTypography>
              <div className={styles.mainBtnContainer}>
                <button
                  className={styles.mainLinkH265}
                  onClick={handleGatewayGuideLink}
                >
                  {t("SETTING_MANUAL_TEXT")}
                  <em className={styles.h265Icon}>
                    <IconComponent
                      className={styles.blueIcon}
                      iconName="arrow_right_blue"
                      iconAlt="icon"
                    />
                    <IconComponent
                      className={styles.whiteIcon}
                      iconName="arrow_right_white"
                      iconAlt="icon"
                    />
                  </em>
                </button>
              </div>
            </AnimatedTypography>
          </div>
        </article>

        <article id={styles.mainDeviceCon} ref={deviceSectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle} data-scroll="fade-up">
                  {t("NAV_ITEM_DEVICE")}
                </h3>
              </AnimatedTypography>
            </div>
            <AnimatedTypography className={styles.mainDeviceBox}>
              <h5 className={styles.mainDeviceBoxTitle}>
                {t("H265_HIGH_QUALITY")}
              </h5>
              <div
                className={`${styles.mainDeviceWrapper} main-device-container swiper-container-initialized swiper-container-horizontal`}
              >
                <SwiperComponent
                  swiperKey="deviceList"
                  showPagination={false}
                  isAutoPlay={false}
                  swiperItems={[
                    <DeviceCard
                      cardTitle={t("CLOUD_H265_CAM")}
                      deviceName={t("TOASTCAM_DOME")}
                      whiteDeviceImgSrc={mainImage.deviceDome}
                      blackDeviceImgSrc={mainImage.deviceDomeBlack}
                      infoLink="/h265-device"
                    >
                      <p>
                        {t("DEVICE_CLOUD_RECORD")}
                        {t("SAVE_H265_VIDEO")}
                      </p>
                      <p>{t("NIGHT_MODE_SUPPORT")}</p>
                      <p>{t("3DNR_WDR_SUPPORT")}</p>
                      <p>{t("IP67_SUPPORT")}</p>
                      <p>{t("SD_CARD_SAVE")}</p>
                      <p>{t("200M_PIXELS_106_VIEW_ANGLE")}</p>
                    </DeviceCard>,
                    <DeviceCard
                      cardTitle={t("CLOUD_H265_CAM")}
                      cardAdditionalTitle={t("AUDIO_REC_SUPPORT")}
                      deviceName={t("TOASTCAM_DOME")}
                      whiteDeviceImgSrc={mainImage.deviceDome}
                      blackDeviceImgSrc={mainImage.deviceDomeBlack}
                      infoLink="/h265-device"
                    >
                      <p>
                        {t("DEVICE_CLOUD_RECORD")}
                        {t("SAVE_H265_VIDEO")}
                      </p>
                      <p>{t("AUDIO_RECORD_SUPPORT")}</p>
                      <p>{t("NIGHT_MODE_SUPPORT")}</p>
                      <p>{t("3DNR_WDR_SUPPORT")}</p>
                      <p>{t("IP67_SUPPORT")}</p>
                      <p>{t("SD_CARD_SAVE")}</p>
                      <p>{t("200M_PIXELS_100_VIEW_ANGLE")}</p>
                    </DeviceCard>,
                    <DeviceCard
                      cardTitle={t("CLOUD_H265_CAM")}
                      cardAdditionalTitle={t("AUDIO_REC_SUPPORT")}
                      deviceName={t("TOASTCAM_BULLET")}
                      whiteDeviceImgSrc={mainImage.deviceBullet}
                      blackDeviceImgSrc={mainImage.deviceBulletBlack}
                      infoLink="/h265-device"
                    >
                      <p>
                        {t("DEVICE_CLOUD_RECORD")}
                        {t("SAVE_H265_VIDEO")}
                      </p>
                      <p>{t("AUDIO_RECORD_SUPPORT")}</p>
                      <p>{t("NIGHT_MODE_SUPPORT")}</p>
                      <p>{t("3DNR_WDR_SUPPORT")}</p>
                      <p>{t("IP67_SUPPORT")}</p>
                      <p>{t("SD_CARD_SAVE")}</p>
                      <p>{t("200M_PIXELS_106_VIEW_ANGLE")}</p>
                    </DeviceCard>,
                  ]}
                ></SwiperComponent>
              </div>
            </AnimatedTypography>
            <AnimatedTypography
              className={styles.mainDeviceBox}
              ref={h264DeviceRef}
            >
              <h5 className={styles.mainDeviceBoxTitle}>
                {t("H264_REASONABLE_TYPE")}
              </h5>
              <div
                className={`${styles.mainDeviceWrapper} main-device-container swiper-container-initialized swiper-container-horizontal`}
              >
                {/* <ul
                  className={classNames(
                    styles.mainDeviceList,
                    styles.swiperWrapper,
                  )}
                >
                  <DeviceCard
                    cardTitle={t("CLOUD_H264_CAM")}
                    deviceName={t("TOASTCAM_DOME")}
                    whiteDeviceImgSrc={mainImage.deviceDome}
                    infoLink="/h264-device"
                  >
                    <p>
                      {t("DEVICE_CLOUD_RECORD")}
                      {t("SAVE_H264_VIDEO")}
                    </p>
                    <p>{t("NIGHT_MODE_SUPPORT")}</p>
                    <p>{t("3DNR_WDR_SUPPORT")}</p>
                    <p>{t("IP67_SUPPORT")}</p>
                    <p>{t("SD_CARD_SAVE")}</p>
                    <p>{t("200M_PIXELS_106_VIEW_ANGLE")}</p>
                  </DeviceCard>
                  <DeviceCard
                    cardTitle={t("CLOUD_H264_CAM")}
                    deviceName={t("TOASTCAM_BULLET")}
                    whiteDeviceImgSrc={mainImage.deviceBullet}
                    infoLink="/h264-device"
                  >
                    <p>
                      {t("DEVICE_CLOUD_RECORD")}
                      {t("SAVE_H264_VIDEO")}
                    </p>
                    <p>{t("AUDIO_RECORD_SUPPORT")}</p>
                    <p>{t("NIGHT_MODE_SUPPORT")}</p>
                    <p>{t("3DNR_WDR_SUPPORT")}</p>
                    <p>{t("IP67_SUPPORT")}</p>
                    <p>{t("SD_CARD_SAVE")}</p>
                    <p>{t("200M_PIXELS_100_VIEW_ANGLE")}</p>
                  </DeviceCard>
                </ul> */}
                <SwiperComponent
                  swiperKey="deviceList_h264"
                  showPagination={false}
                  isAutoPlay={false}
                  swiperItems={[
                    <DeviceCard
                      cardTitle={t("CLOUD_H264_CAM")}
                      deviceName={t("TOASTCAM_DOME")}
                      whiteDeviceImgSrc={mainImage.deviceDome}
                      infoLink="/h264-device"
                    >
                      <p>
                        {t("DEVICE_CLOUD_RECORD")}
                        {t("SAVE_H265_VIDEO")}
                      </p>
                      <p>{t("NIGHT_MODE_SUPPORT")}</p>
                      <p>{t("3DNR_WDR_SUPPORT")}</p>
                      <p>{t("IP67_SUPPORT")}</p>
                      <p>{t("SD_CARD_SAVE")}</p>
                      <p>{t("200M_PIXELS_106_VIEW_ANGLE")}</p>
                    </DeviceCard>,
                    <DeviceCard
                      cardTitle={t("CLOUD_H264_CAM")}
                      deviceName={t("TOASTCAM_BULLET")}
                      whiteDeviceImgSrc={mainImage.deviceBullet}
                      infoLink="/h264-device"
                    >
                      <p>
                        {t("DEVICE_CLOUD_RECORD")}
                        {t("SAVE_H265_VIDEO")}
                      </p>
                      <p>{t("AUDIO_RECORD_SUPPORT")}</p>
                      <p>{t("NIGHT_MODE_SUPPORT")}</p>
                      <p>{t("3DNR_WDR_SUPPORT")}</p>
                      <p>{t("IP67_SUPPORT")}</p>
                      <p>{t("SD_CARD_SAVE")}</p>
                      <p>{t("200M_PIXELS_100_VIEW_ANGLE")}</p>
                    </DeviceCard>,
                  ]}
                ></SwiperComponent>
              </div>
            </AnimatedTypography>
            <div className={styles.mainDeviceBox}>
              <div className={styles.mainDeviceBoxInner} ref={v4Ref}>
                <AnimatedTypography className={styles.mainDeviceBoxInnerItem}>
                  <h5 className={styles.mainDeviceBoxTitle}>{t("AI_WIRE")}</h5>
                  <div className={`${styles.mainDeviceWrapper}`}>
                    {/* <ul
                      className={classNames(
                        styles.mainDeviceList,
                        styles.swiperWrapper,
                      )}
                    >
                      <DeviceCard
                        cardTitle={t("DEVICE_AI_FACE")}
                        deviceName="Va4 AI"
                        className={styles.mainDeviceItem}
                        whiteDeviceImgSrc={mainImage.deviceV4}
                        // infoLink={"/v4"}
                      >
                        <p>{t("AI_ENGINE_BOARD")}</p>
                        <p>{t("NIGHT_MODE_SUPPORT")}</p>
                        <p>{t("3DNR_SUPPORT")}</p>
                        <p>{t("SONY_IMAGE_SENSOR")}</p>
                        <p>{t("IP66_SUPPORT")}</p>
                        <p>{t("200M_PIXELS_135_VIEW_ANGLE")}</p>
                      </DeviceCard>
                      <DeviceCard
                        cardTitle={t("DEVICE_AI_PEOPLE")}
                        deviceName="V4 AI +"
                        whiteDeviceImgSrc={mainImage.deviceV4}
                        className={styles.mainDeviceItem}
                        // infoLink={"/v4"}
                      >
                        <p>{t("AI_ENGINE_BOARD")}</p>
                        <p>{t("AI_ACCELATOR")}</p>
                        <p>{t("NIGHT_MODE_SUPPORT")}</p>
                        <p>{t("3DNR_SUPPORT")}</p>
                        <p>{t("SONY_IMAGE_SENSOR")}</p>
                        <p>{t("IP66_SUPPORT")}</p>
                        <p>{t("200M_PIXELS_135_VIEW_ANGLE")}</p>
                      </DeviceCard>
                    </ul> */}
                    <SwiperComponent
                      swiperKey="deviceList_ai"
                      showPagination={false}
                      isAutoPlay={false}
                      swiperSlideClassName={styles.mainDeviceInnerItem}
                      swiperItems={[
                        <DeviceCard
                          cardTitle={t("DEVICE_AI_FACE")}
                          deviceName="V4 AI"
                          className={styles.mainDeviceItem}
                          whiteDeviceImgSrc={mainImage.deviceV4}
                          infoLink={"/v4"}
                        >
                          <p>{t("AI_ENGINE_BOARD")}</p>
                          <p>{t("NIGHT_MODE_SUPPORT")}</p>
                          <p>{t("3DNR_SUPPORT")}</p>
                          <p>{t("SONY_IMAGE_SENSOR")}</p>
                          <p>{t("IP66_SUPPORT")}</p>
                          <p>{t("200M_PIXELS_135_VIEW_ANGLE")}</p>
                        </DeviceCard>,
                        <DeviceCard
                          cardTitle={t("DEVICE_AI_PEOPLE")}
                          deviceName="V4 AI +"
                          whiteDeviceImgSrc={mainImage.deviceV4}
                          className={styles.mainDeviceItem}
                          infoLink={"/v4"}
                        >
                          <p>{t("AI_ENGINE_BOARD")}</p>
                          <p>{t("AI_ACCELATOR")}</p>
                          <p>{t("NIGHT_MODE_SUPPORT")}</p>
                          <p>{t("3DNR_SUPPORT")}</p>
                          <p>{t("SONY_IMAGE_SENSOR")}</p>
                          <p>{t("IP66_SUPPORT")}</p>
                          <p>{t("200M_PIXELS_135_VIEW_ANGLE")}</p>
                        </DeviceCard>,
                      ]}
                    ></SwiperComponent>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography className={styles.mainDeviceBoxInnerItem}>
                  <h5 className={styles.mainDeviceBoxTitle}>
                    {t("COMBINE_TYPE")}
                  </h5>
                  <div className={`${styles.mainDeviceWrapper}`}>
                    <SwiperComponent
                      swiperKey="deviceList"
                      showPagination={false}
                      isAutoPlay={false}
                      swiperSlideClassName={styles.mainDeviceInnerItem}
                      swiperItems={[
                        <DeviceCard
                          cardTitle={t("GATEWAY")}
                          deviceName="Gateway - IP"
                          className={styles.mainDeviceItem}
                          whiteDeviceImgSrc={mainImage.deviceGateway}
                        >
                          <p>{t("ONVIF_IP_CAM_SUPPORT")}</p>
                          <p>{t("LOCAL_MONITORING")}</p>
                          <p>{t("SD_CARD_SAVE")}</p>
                        </DeviceCard>,
                      ]}
                    ></SwiperComponent>
                  </div>
                </AnimatedTypography>
              </div>
            </div>
          </div>
        </article>

        {/* <article id={styles.mainPlanCon} ref={costPlainSectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle} data-scroll="fade-up">
                  <b>{t("SECTION_TITLE_CLOUD_COST_FRAGMENT")}</b>
                  {t("SECTION_TITLE_CLOUD_COST_FRAGMENT2")}
                </h3>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubTitle} data-scroll="fade-up">
                  {t("SECTION_SUB_TITLE_CLOUD_COST")}
                </p>
              </AnimatedTypography>
              <AnimatedTypography>
                <p className={styles.mainSubText} data-scroll="fade-up">
                  {t("SECTION_SUB_STRING1_CLOUD_COST")}
                </p>
              </AnimatedTypography>
            </div>
            <div className={styles.mainPlanWrapper}>
              <ul
                className={styles.mainPlanList}
                data-rolling-time="3000"
                data-reset-width="800"
              >
                {PLANS.map(
                  ({ id, animationDelay, name, price, recordDuration }) => (
                    <AnimatedTypography
                      tag="li"
                      key={id}
                      className={styles.mainPlanItem}
                      animationDelay={animationDelay}
                    >
                      <div
                        className={classNames(
                          styles.mainPlanInner,
                          planIndex === id && styles.mainPlanActive,
                        )}
                      >
                        <span className={styles.plan}>{name}</span>
                        <p className={styles.fare}>
                          円<b>{price}</b>
                          <em>/ {t("MONTH")}</em>
                        </p>
                        <p className={styles.txt}>
                          {t("SECTION_DESCRIBE1_CLOUD_COST_FRAGMENT")}
                          {recordDuration}
                          {t("SECTION_DESCRIBE1_CLOUD_COST_FRAGMENT2")}
                        </p>
                      </div>
                    </AnimatedTypography>
                  ),
                )}
              </ul>
            </div>
            <AnimatedTypography className={styles.mainPlanTxt}>
              <p className={styles.txt01}>
                {t("SECTION_SUB_STRING2_CLOUD_COST")}
              </p>
              <p className={styles.txt02}>
                {t("SECTION_ADDITIONAL_TEXT_CLOUD_COST")}
              </p>
            </AnimatedTypography>
          </div>
        </article> */}

        <article id={styles.mainAiCon} ref={additionalServiceSectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_AI_ADDITIONAL_SERVICE_FRAGMENT")}
                  <b>{t("SECTION_TITLE_AI_ADDITIONAL_SERVICE_FRAGMENT2")}</b>
                </h3>
              </AnimatedTypography>
            </div>
            <div className={styles.mainAiWrapper}>
              <ul className={styles.mainAiList}>
                <AnimatedTypography tag="li" className={styles.mainAiItem}>
                  <div
                    className={classNames(styles.mainAiImg, styles.mainAiImg01)}
                  ></div>
                  <div className={styles.mainAiTxt}>
                    <h5 className={styles.tit}>{t("FACE_DETECT")}</h5>
                    <p className={styles.subTit}>
                      {t("FACE_DETECT_SUB_TITLE")}
                    </p>
                    <p className={styles.txt}>{t("FACE_DETECT_SUB_STRING")}</p>
                  </div>
                </AnimatedTypography>
                <AnimatedTypography tag="li" className={styles.mainAiItem}>
                  <div
                    className={classNames(styles.mainAiImg, styles.mainAiImg02)}
                  ></div>
                  <div className={styles.mainAiTxt}>
                    <h5 className={styles.tit}>{t("PEOPLE_DETECT")}</h5>
                    <p className={styles.subTit}>
                      {t("PEOPLE_DETECT_SUB_TITLE")}
                    </p>
                    <p className={styles.txt}>
                      {t("PEOPLE_DETECT_SUB_STRING")}
                    </p>
                  </div>
                </AnimatedTypography>
              </ul>
            </div>
          </div>
        </article>

        <article id={styles.mainCaseCon}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle} data-scroll="fade-up">
                  {t("SECTION_TITLE_USAGE_FRAGMENT")}
                  <b>{t("SECTION_TITLE_USAGE_FRAGMENT2")}</b>
                  {t("SECTION_TITLE_USAGE_FRAGMENT3")}
                </h3>
              </AnimatedTypography>
            </div>
            <ul className={styles.mainCaseList}>
              <AnimatedTypography tag="li" className={styles.mainCaseItem}>
                <div
                  className={classNames(
                    styles.mainCaseImg,
                    styles.mainCaseImg01,
                  )}
                ></div>
                <div className={styles.mainCaseBottom}>
                  <div className={styles.mainCaseTxt}>
                    <img
                      className={styles.icon}
                      src={mainImage.caseIcon01}
                      alt="아이콘"
                    />
                    <h5 className={styles.tit}>{t("APT_MANAGEMENT")}</h5>
                    <p className={styles.txt}>
                      {t("APT_MANAGEMENT_SUB_STRING")}
                    </p>
                  </div>
                  <div className={styles.mainCaseBtn}>
                    <Link to="/h264-device" className={styles.mainCaseBtnLink}>
                      {t("USE_DEVICE")}
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.caseUsbBlueIcon}
                          iconName="icon_usb_drive_blue"
                          iconAlt="usb아이콘"
                        />
                        <IconComponent
                          className={styles.caseUsbWhiteIcon}
                          iconName="icon_usb_drive_white"
                          iconAlt="usb아이콘"
                        />
                      </em>
                    </Link>
                  </div>
                </div>
              </AnimatedTypography>
              <AnimatedTypography tag="li" className={styles.mainCaseItem}>
                <div
                  className={classNames(
                    styles.mainCaseImg,
                    styles.mainCaseImg02,
                  )}
                ></div>
                <div className={styles.mainCaseBottom}>
                  <div className={styles.mainCaseTxt}>
                    <img
                      className={styles.icon}
                      src={mainImage.caseIcon02}
                      alt="아이콘"
                    />
                    <h5 className={styles.tit}>{t("CONSTRUCTION_SITE")}</h5>
                    <p className={styles.txt}>
                      {t("CONSTRUCTION_SITE_SUB_STRING")}
                    </p>
                  </div>
                  <div className={styles.mainCaseBtn}>
                    <Link to="/h265-device" className={styles.mainCaseBtnLink}>
                      {t("USE_DEVICE")}
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.caseUsbBlueIcon}
                          iconName="icon_usb_drive_blue"
                          iconAlt="usb아이콘"
                        />
                        <IconComponent
                          className={styles.caseUsbWhiteIcon}
                          iconName="icon_usb_drive_white"
                          iconAlt="usb아이콘"
                        />
                      </em>
                    </Link>
                  </div>
                </div>
              </AnimatedTypography>
              <AnimatedTypography tag="li" className={styles.mainCaseItem}>
                <div
                  className={classNames(
                    styles.mainCaseImg,
                    styles.mainCaseImg03,
                  )}
                ></div>
                <div className={styles.mainCaseBottom}>
                  <div className={styles.mainCaseTxt}>
                    <img
                      className={styles.icon}
                      src={mainImage.caseIcon03}
                      alt="아이콘"
                    />
                    <h5 className={styles.tit}>{t("CAFE_RESTAURANT")}</h5>
                    <p className={styles.txt}>
                      {t("CAFE_RESTAURANT_SUB_STRING")}
                    </p>
                  </div>
                  <div className={styles.mainCaseBtn}>
                    <Link to="/v4" className={styles.mainCaseBtnLink}>
                      {t("USE_DEVICE")}
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.caseUsbBlueIcon}
                          iconName="icon_usb_drive_blue"
                          iconAlt="usb아이콘"
                        />
                        <IconComponent
                          className={styles.caseUsbWhiteIcon}
                          iconName="icon_usb_drive_white"
                          iconAlt="usb아이콘"
                        />
                      </em>
                    </Link>
                  </div>
                </div>
              </AnimatedTypography>
            </ul>
          </div>
        </article>

        <article id={styles.mainCustomerCon} ref={inquirySectionRef}>
          <div className={layoutStyles.area}>
            <div className={styles.mainTitleBox}>
              <AnimatedTypography>
                <h3 className={styles.mainTitle}>
                  {t("SECTION_TITLE_END_FRAGMENT")}
                  <b>{t("SECTION_TITLE_END_FRAGMENT2")}</b>
                  {t("SECTION_TITLE_END_FRAGMENT3")}
                </h3>
              </AnimatedTypography>
            </div>
            <div className={styles.mainCustomerWrapper}>
              <ul className={styles.mainCustomerList}>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainCustomerItem}
                  animationDelay={100}
                >
                  <Link to="online-inquiry">
                    <div className={styles.mainCustomerImg}>
                      <img src={mainImage.customerIcon01} alt="아이콘" />
                    </div>
                    <div className={styles.mainCustomerTxt}>
                      <p>{t("PRODUCT_INQUIRY")}</p>
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.blue}
                          iconName="icon_long_arrow_right_blue"
                          iconAlt="icon"
                        />
                        <IconComponent
                          className={styles.gray}
                          iconName="icon_long_arrow_right_gray"
                          iconAlt="icon"
                        />
                      </em>
                    </div>
                  </Link>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainCustomerItem}
                  animationDelay={250}
                >
                  <Link to="/partner" onClick={handleLinkPartner}>
                    <div className={styles.mainCustomerImg}>
                      <img src={mainImage.customerIcon02} alt="아이콘" />
                    </div>
                    <div className={styles.mainCustomerTxt}>
                      <p>{t("PARTNERSHIP_INQUIRY")}</p>
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.blue}
                          iconName="icon_long_arrow_right_blue"
                          iconAlt="icon"
                        />
                        <IconComponent
                          className={styles.gray}
                          iconName="icon_long_arrow_right_gray"
                          iconAlt="icon"
                        />
                      </em>
                    </div>
                  </Link>
                </AnimatedTypography>
                <AnimatedTypography
                  tag="li"
                  className={styles.mainCustomerItem}
                  animationDelay={400}
                >
                  <Link to="faq">
                    <div className={styles.mainCustomerImg}>
                      <img src={mainImage.customerIcon03} alt="아이콘" />
                    </div>
                    <div className={styles.mainCustomerTxt}>
                      <p>FAQ</p>
                      <em className={styles.icon}>
                        <IconComponent
                          className={styles.blue}
                          iconName="icon_long_arrow_right_blue"
                          iconAlt="icon"
                        />
                        <IconComponent
                          className={styles.gray}
                          iconName="icon_long_arrow_right_gray"
                          iconAlt="icon"
                        />
                      </em>
                    </div>
                  </Link>
                </AnimatedTypography>
              </ul>
            </div>
          </div>
        </article>
      </section>
    </div>
  );
};

export default HomePage;
