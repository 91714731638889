import useLanguageStore from "@/store/language";
import useViewportStore from "@/store/viewport";
import { ImgHTMLAttributes, useMemo } from "react";

export interface ImageComponentProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  moSrc?: string;
  jpMoSrc?: string;
  jpSrc?: string;
}

const ImageComponent = ({
  moSrc,
  jpMoSrc,
  jpSrc,
  src,
  alt,
  ...etc
}: ImageComponentProps) => {
  const { isMobile } = useViewportStore((state) => state);
  const { isLanguageKr } = useLanguageStore((state) => state);

  const imageSrc = useMemo(() => {
    if (isLanguageKr) {
      return isMobile ? moSrc || src : src;
    } else {
      if (!jpSrc && !jpMoSrc) {
        return isMobile ? moSrc || src : src;
      }

      return isMobile ? jpMoSrc || jpSrc : jpSrc;
    }
  }, [isLanguageKr, isMobile, moSrc, jpMoSrc, jpSrc, src]);

  return <img src={imageSrc} alt={alt} {...etc} />;
};

export default ImageComponent;
