import { create } from "zustand";
import { MutableRefObject } from "react";

type InterSectionType =
  | "toastcam"
  | "service"
  | "device"
  | "costPlain"
  | "additionalService"
  | "inquiry"
  | "";

interface InterSectionState {
  mainInterSection: InterSectionType;
  swiperContainerRef: MutableRefObject<HTMLElement | null>;
  toastcamRef: MutableRefObject<HTMLElement | null>;
  serviceRef: MutableRefObject<HTMLElement | null>;
  deviceRef: MutableRefObject<HTMLElement | null>;
  costPlainRef: MutableRefObject<HTMLElement | null>;
  additionalServiceRef: MutableRefObject<HTMLElement | null>;
  inquiryRef: MutableRefObject<HTMLElement | null>;
  setMainInterSection: (mainView: InterSectionType) => void;
  setRef: (
    key: keyof Omit<
      InterSectionState,
      "mainInterSection" | "setMainInterSection" | "setRef"
    >,
    ref: MutableRefObject<HTMLElement | null>,
  ) => void;
}

const useIntersectionStore = create<InterSectionState>((set) => ({
  mainInterSection: "",
  swiperContainerRef: { current: null },
  toastcamRef: { current: null },
  serviceRef: { current: null },
  deviceRef: { current: null },
  costPlainRef: { current: null },
  additionalServiceRef: { current: null },
  inquiryRef: { current: null },
  setMainInterSection: (interSection: InterSectionType) =>
    set({ mainInterSection: interSection }),
  setRef: (key, ref) => set({ [key]: ref }),
}));

export default useIntersectionStore;
export type { InterSectionType, InterSectionState };
