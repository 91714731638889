import { create } from "zustand";

export type LanguageType = "ko" | "jp";

export interface LanguageState {
  language: string;
  isLanguageKr: boolean;
  setLanguage: (lang: LanguageType) => void;
}

const useLanguageStore = create<LanguageState>((set) => ({
  language: "ko",
  isLanguageKr: true,
  setLanguage: (lang: LanguageType) =>
    set({ language: lang, isLanguageKr: lang === "ko" }),
}));

export default useLanguageStore;
