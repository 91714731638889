import classNames from "classnames";
import { IconName } from "@/types/iconName";

export interface IconProps {
  iconName: IconName;
  iconAlt: string;
  className?: string;
}

const IconComponent = ({ iconName, iconAlt, className = "" }: IconProps) => {
  // const iconSrc = require(`../../assets/icon/${iconName}.svg`);

  const iconSrc = new URL(`../../assets/icon/${iconName}.svg`, import.meta.url)
    .href;

  return (
    <img
      className={classNames("icon-container", className)}
      src={iconSrc}
      alt={iconAlt}
    />
  );
};

export default IconComponent;
