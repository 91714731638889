import { useEffect, useState } from "react";
import { isMobile, detectOS, detectBrowser } from "@/utils/device";

interface DeviceInfo {
  isMobile: boolean;
  os: string;
  browser: string;
}

const useDetectDevice = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    isMobile: false,
    os: "unknown",
    browser: "unknown",
  });

  useEffect(() => {
    const updateDeviceInfo = () => {
      setDeviceInfo({
        isMobile: isMobile(),
        os: detectOS(),
        browser: detectBrowser(),
      });
    };

    updateDeviceInfo();

    window.addEventListener("resize", updateDeviceInfo);

    return () => {
      window.removeEventListener("resize", updateDeviceInfo);
    };
  }, []);

  return deviceInfo;
};

export default useDetectDevice;
