import { create } from "zustand";

type ViewportState = {
  isMobile: boolean;
  viewPortHeight: number;
  viewPortWidth: number;
  setIsMobile: (isMobile: boolean) => void;
  setViewPortHeight: (height: number) => void;
  setViewPortWidth: (width: number) => void;
};

const useViewportStore = create<ViewportState>((set) => ({
  isMobile: false,
  viewPortHeight: window.innerHeight,
  viewPortWidth: window.innerWidth,
  setIsMobile: (isMobile: boolean) => set({ isMobile }),
  setViewPortHeight: (height: number) => set({ viewPortHeight: height }),
  setViewPortWidth: (width: number) => set({ viewPortWidth: width }),
}));

export default useViewportStore;
