export const detectOS = (): string => {
  const userAgent = window.navigator.userAgent;
  if (/Windows/i.test(userAgent)) return "windows";
  if (/Mac/i.test(userAgent)) return "mac";
  if (/Linux/i.test(userAgent)) return "linux";
  if (/Android/i.test(userAgent)) return "android";
  if (/iOS/i.test(userAgent)) return "ios";
  return "unknown";
};

export const detectBrowser = (): string => {
  const userAgent = window.navigator.userAgent;
  if (/Chrome/i.test(userAgent)) return "chrome";
  if (/Firefox/i.test(userAgent)) return "firefox";
  if (/Safari/i.test(userAgent)) return "safari";
  if (/Edge/i.test(userAgent)) return "edge";
  if (/Opera/i.test(userAgent)) return "opera";
  if (/MSIE|Trident/i.test(userAgent)) return "ie"; // Internet Explorer
  return "unknown";
};

export const detectIpad = (): boolean => {
  const userAgent = window.navigator.userAgent;
  return (
    /iPad/.test(userAgent) &&
    /Safari/.test(userAgent) &&
    !/Chrome/.test(userAgent)
  );
};

export const isMobile = (): boolean => {
  const userAgent = window.navigator.userAgent;
  if (
    userAgent.match(
      /iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null ||
    userAgent.match(/LG|SAMSUNG|Samsung/) != null
  ) {
    return true;
  } else {
    // iPad Safari Browser
    return detectIpad();
  }
};
