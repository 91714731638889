import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "@/styles/header.module.scss";
import layoutStyles from "@/styles/commonLayout.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logoWhite from "@/assets/img/toastcam_logo_white.png";
import logoBlue from "@/assets/img/toastcam_logo_blue.png";
import IconComponent from "../shared/Icon";
import useIntersectionStore from "@/store/intersection";
import useViewportStore from "@/store/viewport";
import MobileAsideNav from "../MobileAsideNav";
import { useTranslation } from "react-i18next";
import { scrollToTarget, scrollToTop } from "@/utils/scroll";

export interface HeaderProps {
  isFixedTop?: boolean;
}

const CHANGE_STYLE_HEADER_PATH = ["/"];

const Header = ({ isFixedTop = true }: HeaderProps) => {
  useEffect(() => {
    const img = new Image();
    // img.src = "/src/assets/img/toastcam_logo_white.png";
    img.src = logoWhite;
  }, []);

  const { isMobile } = useViewportStore((state) => state);
  const { t } = useTranslation();

  const {
    mainInterSection,
    toastcamRef,
    serviceRef,
    deviceRef,
    costPlainRef,
    additionalServiceRef,
    inquiryRef,
    setMainInterSection,
  } = useIntersectionStore((state) => state);

  const navItems = [
    {
      id: "mainFutureCon",
      label: t("NAV_ITEM_TOAST_CAM"),
      section: "toastcam",
      ref: toastcamRef,
    },
    {
      id: "mainCloudCon",
      label: t("NAV_ITEM_SERVICE"),
      section: "service",
      ref: serviceRef,
    },
    {
      id: "mainDeviceCon",
      label: t("NAV_ITEM_DEVICE"),
      section: "device",
      ref: deviceRef,
    },
    // {
    //   id: "mainPlanCon",
    //   label: t("NAV_ITEM_COST"),
    //   section: "costPlain",
    //   ref: costPlainRef,
    // },
    {
      id: "mainAiCon",
      label: t("NAV_ITEM_ADDITIONAL_SERVICE"),
      section: "additionalService",
      ref: additionalServiceRef,
    },
    {
      id: "mainCustomerCon",
      label: t("NAV_ITEM_INQUIRY"),
      section: "inquiry",
      ref: inquiryRef,
    },
  ];

  const [isTopScroll, setIsTopScroll] = useState(true);
  const [isLoginIconHovered, setIsLoginIconHovered] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(isMobile ?? false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLinkHome = () => {
    const isHome = location.pathname === "/";

    if (isHome) return scrollToTop("smooth");
    navigate("/");
  };

  const changeStyleHeader = CHANGE_STYLE_HEADER_PATH.includes(
    location.pathname,
  );

  useEffect(() => {
    const isHome = location.pathname === "/";

    if (!isHome) setMainInterSection("");
  }, [location, setMainInterSection]);

  useEffect(() => {
    if (!isMobile) setIsMobileNavOpen(false);
  }, [isMobile]);

  const isTopFixedHeader = useMemo(() => {
    return !changeStyleHeader || isFixedTop || !isTopScroll;
  }, [isFixedTop, isTopScroll, changeStyleHeader]);

  const loginIconPath = useMemo(
    () =>
      isLoginIconHovered
        ? isTopFixedHeader
          ? "icon_profile_white"
          : "icon_profile_blue"
        : isTopFixedHeader
          ? "icon_profile_black"
          : "icon_profile_white",
    [isLoginIconHovered, isTopFixedHeader],
  );

  const handleScroll = useCallback(() => {
    setIsTopScroll(window.scrollY === 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const moveScrollToRef = (
    targetRef: React.RefObject<HTMLElement>,
    targetSection: string,
  ): void => {
    const isHome = location.pathname === "/";
    if (!isHome) {
      navigate(`/?target=${targetSection}`);
      return;
    }
    scrollToTarget(targetRef);
  };

  const handleMobileAside = (
    targetRef: React.RefObject<HTMLElement>,
    targetSection: string,
  ) => {
    const isMain = location.pathname === "/";

    if (isMain) {
      moveScrollToRef(targetRef, targetSection);
      setIsMobileNavOpen(false);

      return;
    }

    navigate("/");
    setIsMobileNavOpen(false);
    moveScrollToRef(targetRef, targetSection);
  };

  return (
    <header
      id={styles.header}
      className={classNames(
        "",
        isTopFixedHeader ? styles.fixedTopHeader : styles.fixedHeader,
      )}
    >
      <div className={layoutStyles.gnbOverlayBg}></div>
      <div id={styles.headerInnerWrap}>
        <div
          id={styles.headerInner}
          className={classNames(layoutStyles.areaWide)}
        >
          <h1 className={styles.logo}>
            <Link to="/" onClick={handleLinkHome} title="to home">
              <img
                className={isTopFixedHeader ? "blue" : "white"}
                src={isTopFixedHeader ? logoBlue : logoWhite}
                alt="logo"
              />
            </Link>
          </h1>
          <div className={styles.headerUtilBox}>
            <a
              href={import.meta.env.VITE_LOGIN_URL}
              rel="noreferrer"
              target="_blank"
              className={styles.headerLoginBtn}
              onMouseEnter={() => setIsLoginIconHovered(true)}
              onMouseLeave={() => setIsLoginIconHovered(false)}
            >
              <em>{t("USER_LOGIN")}</em>
              <em className={styles.icon}>
                <IconComponent iconName={loginIconPath} iconAlt="유저 로그인" />
              </em>
            </a>
          </div>
        </div>
        <nav id={styles.gnb} className="each-menu">
          <h2 className={styles.blind}>Menu</h2>
          <ul
            className={classNames(
              "clearfix",
              "gnb-list-JS",
              layoutStyles.areaWide,
            )}
          >
            {navItems.map((item) => (
              <li
                key={item.id}
                className={classNames(
                  styles.gnbItem,
                  mainInterSection === item.section && styles.selectedGnbItem,
                )}
              >
                <button onClick={() => moveScrollToRef(item.ref, item.section)}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <button
        className={classNames(
          styles.navOpenBtn,
          isMobileNavOpen && styles.navCloseBtn,
        )}
        title="Menu Open"
        onClick={() => setIsMobileNavOpen((current) => !current)}
      >
        <span className={classNames(styles.line, styles.line1)}></span>
        <span className={classNames(styles.line, styles.line2)}></span>
      </button>
      <div className="gnb-overlay-bg-m"></div>
      <MobileAsideNav isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen}>
        <nav
          id={styles.gnbM}
          className={classNames(isMobileNavOpen && styles.openGnbM)}
        >
          <h2 className={styles.blind}>Menu</h2>
          <div className={styles.gnbMWrapper}>
            <div className="gnb-navigation-inner">
              <ul id="navigation" data-menu-clone="true">
                {navItems.map((item) => (
                  <li key={item.id}>
                    <button
                      className={styles.gnbMItem}
                      onClick={() => handleMobileAside(item.ref, item.section)}
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </MobileAsideNav>
    </header>
  );
};

export default Header;
