import { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  },
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observerCallback = ([entry]: IntersectionObserverEntry[]) => {
      setIsIntersecting(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, options);
    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [ref, options]);

  return { ref, isIntersecting };
};

export default useIntersectionObserver;
