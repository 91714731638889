import styles from "@/styles/footer.module.scss";
import layoutStyles from "@/styles/commonLayout.module.scss";

import LogoBlack from "@/assets/img/company_logo_black.png";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconComponent from "../shared/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { scrollToTop } from "@/utils/scroll";
import useLanguageStore from "@/store/language";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const NO_BOTTOM_BACK_BTN_LOCATION = ["/"];
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const { isLanguageKr } = useLanguageStore();

  const location = useLocation();
  const navigate = useNavigate();

  const isPartnerLocation = useMemo(
    () => location.pathname === "partner",
    [location],
  );

  const handleLinkHome = () => {
    const isHome = location.pathname === "/";

    if (isHome) return scrollToTop("smooth");
    navigate("/");
  };

  const noBottomBackBtn = NO_BOTTOM_BACK_BTN_LOCATION.includes(
    location.pathname,
  );

  const handleScroll = useCallback(() => {
    setIsTopScroll(window.scrollY === 0);
  }, []);

  const toggleDropdown = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropDownOpen(false);
  };

  const handlePrivateTermLink = () => {
    const url = `${import.meta.env.VITE_LOGIN_URL}${isLanguageKr ? "privateTerms" : "privateTermsJP"}`;
    window.open(
      url,
      "_blank",
      "width=600,height=620,top=50,left=50,scrollbars=no",
    );
  };

  const handleTermLink = () => {
    const url = `${import.meta.env.VITE_LOGIN_URL}${isLanguageKr ? "#/terms" : "#/termsJP"}`;
    window.open(
      url,
      "_blank",
      "width=600,height=620,top=50,left=50,scrollbars=no",
    );
  };
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <footer id={styles.footer}>
      <article id={styles.footerTop}>
        <div className={classNames(layoutStyles.areaBox, styles.areaBox)}>
          <div className={styles.footerLeftCon}>
            <Link to="/" onClick={handleLinkHome}>
              <span className={styles.footerLogo}>
                <img src={LogoBlack} alt="logo" />
              </span>
            </Link>
            {isLanguageKr && (
              <div className={styles.footerAddressInfoBox}>
                <div className={styles.footerAddressList}>
                  <dl>
                    <dt>상호 :</dt>
                    <dd>엔에이치엔 토스트캠 (주)</dd>
                  </dl>
                  <dl>
                    <dt>대표 :</dt>
                    <dd>하태년</dd>
                  </dl>
                </div>
                <div className={styles.footerAddressList}>
                  <dl>
                    <dt>주소 :</dt>
                    <dd>
                      경기도 성남시 분당구 대왕판교로 645번길 16 엔에이치엔
                      플레이뮤지엄
                    </dd>
                  </dl>
                  <dl>
                    <dt>이메일 :</dt>
                    <dd>
                      <a href="mailto:dl_toastcamcs@nhn.com">
                        dl_toastcamcs@nhn.com
                      </a>
                    </dd>
                  </dl>
                </div>
                <div className={styles.footerAddressList}>
                  <dl>
                    <dt>사업자 등록번호 :</dt>
                    <dd>144-81-15549</dd>
                  </dl>
                  <dl>
                    <dt>통신 판매 신고번호 :</dt>
                    <dd>2013-경기성남-1067호 사업자 정보확인</dd>
                  </dl>
                </div>
              </div>
            )}
          </div>
          <div className={styles.footerRightMenuCon}>
            <div
              className={classNames(styles.familySiteBox, styles.cmDropMenuBox)}
              onMouseLeave={closeDropdown}
            >
              <ul
                id={styles.footerFamilyList}
                className={classNames(
                  styles.familySiteList,
                  styles.cmDropList,
                  isDropDownOpen ? styles.open : styles.close,
                )}
              >
                <li>
                  <a
                    href={import.meta.env.VITE_PARKING_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TOAST CAM Parking
                  </a>
                </li>
                <li>
                  <a
                    href={import.meta.env.VITE_B2C_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TOAST CAM
                  </a>
                </li>
                <li>
                  <a
                    href={import.meta.env.VITE_MARKET_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    TOAST Market
                  </a>
                </li>
              </ul>
              <button
                className={classNames(
                  styles.familySiteOpenBtn,
                  styles.cmDropOpenBtn,
                )}
                onClick={() => toggleDropdown()}
              >
                Family Site
                <em className={styles.icon}>
                  <IconComponent
                    className={styles.white}
                    iconName="icon_angle_down_blue"
                    iconAlt="icon"
                  />
                </em>
              </button>
            </div>
            <Link to="/online-inquiry" className={styles.footerOnlineBtn}>
              {t("INQUIRY")}
              <em className={styles.icon}>
                <IconComponent iconName="icon_pen" iconAlt="icon" />
              </em>
            </Link>
          </div>
        </div>
      </article>
      <article id={styles.footerBottom}>
        <div className={classNames(layoutStyles.areaBox, styles.areaBox)}>
          <article className={styles.footerLeftCon}>
            <ul className={classNames(styles.footMenu)}>
              <li>
                <button
                  onClick={handlePrivateTermLink}
                  className={classNames(
                    styles.bottomModalBtn,
                    styles.policyButton,
                  )}
                >
                  <b>{t("PRIVACY_POLICY")}</b>
                </button>
              </li>
              <li>
                <button
                  className={styles.bottomModalBtn}
                  onClick={handleTermLink}
                >
                  {t("USE_TERMS")}
                </button>
              </li>
            </ul>
          </article>
          <article className={styles.footerRightCon}>
            <div className={styles.footerCopyright}>
              © NHN TOASTCAM Corp. All rights reserved.
            </div>
          </article>
        </div>
      </article>
      {!noBottomBackBtn && (
        <button
          className={classNames(
            styles.toBackBtn,
            // isPartnerLocation && styles.
            !isTopScroll && styles.bottomFixed,
          )}
          onClick={() => navigate(-1)}
        >
          <em className={styles.icon}>
            <IconComponent iconName="icon_hamburger_back" iconAlt="icon" />
          </em>
          <em className="txt">Back</em>
        </button>
      )}
      <button
        onClick={() => scrollToTop("smooth")}
        className={classNames(
          styles.toTopBtn,
          !isTopScroll && styles.bottomFixed,
        )}
      >
        <em className={styles.icon}>
          <IconComponent
            className={styles.white}
            iconName="icon_arrow_up"
            iconAlt="icon"
          />
        </em>
        <em className={styles.txt}>TOP</em>
      </button>
    </footer>
  );
};

export default Footer;
