import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import styles from "@/styles/AnimatedImage.module.scss";
import classNames from "classnames";
import useLanguageStore from "@/store/language";
import useViewportStore from "@/store/viewport";

export interface AnimatedImageProps {
  src: string;
  jpSrc?: string;
  moSrc?: string;
  jpMoSrc?: string;
  alt?: string;
  animationSpeed?: number;
  /** millisecond */
  animationDelay?: number;
  initialTranslate3d?: InitialTranslate3dProps;
  className?: string;
  id?: string;
}

export interface InitialTranslate3dProps {
  x?: string;
  y?: string;
  z?: string;
}

const AnimatedImage = forwardRef<HTMLImageElement, AnimatedImageProps>(
  (
    {
      src,
      moSrc = "",
      jpMoSrc = "",
      jpSrc = "",
      alt = "",
      animationSpeed = 0.8,
      animationDelay = 0,
      className = "",
      initialTranslate3d = {
        x: "0",
        y: "30px",
        z: "0",
      },
      id = "",
    },
    ref,
  ) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const { isLanguageKr } = useLanguageStore();
    const [isVisible, setIsVisible] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const { isMobile } = useViewportStore();

    const localizedImgSrc = useMemo(() => {
      if (isLanguageKr) {
        return isMobile ? moSrc || src : src;
      } else {
        if (!jpSrc && !jpMoSrc) {
          return isMobile ? moSrc || src : src;
        }

        return isMobile ? jpMoSrc || jpSrc : jpSrc;
      }
    }, [isLanguageKr, isMobile, moSrc, jpMoSrc, jpSrc, src]);

    const { x, y, z } = initialTranslate3d;
    const translate3D = `translate3d(${x}, ${y}, ${z})`;

    // Forward ref to parent component
    useImperativeHandle(ref, () => imageRef.current as HTMLImageElement);

    const handleScroll = useCallback(() => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos;

      setPrevScrollPos(currentScrollPos);

      if (isScrollingDown) {
        if (imageRef.current && observerRef.current) {
          observerRef.current.observe(imageRef.current);
        }
      }
    }, [prevScrollPos]);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else if (entry.boundingClientRect.top > 0) {
              setIsVisible(false);
            }
          });
        },
        {
          threshold: 0.1,
        },
      );

      const imageRefCurrent = imageRef.current;

      if (imageRef.current && observerRef.current) {
        observerRef.current.observe(imageRef.current);
      }

      return () => {
        if (observerRef.current && imageRefCurrent) {
          observerRef.current.unobserve(imageRefCurrent);
        }
      };
    }, []);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [prevScrollPos, handleScroll]);

    useEffect(() => {
      if (imageRef.current) {
        (imageRef.current as HTMLImageElement).style.transitionDuration =
          `${animationSpeed}s`;
        (imageRef.current as HTMLImageElement).style.transitionDelay =
          `${animationDelay}ms`;
        (imageRef.current as HTMLImageElement).style.transform = isVisible
          ? "none"
          : translate3D;
      }
    }, [isVisible, animationSpeed, animationDelay, translate3D]);

    return (
      <img
        id={id}
        ref={imageRef}
        src={localizedImgSrc}
        alt={alt}
        className={classNames(
          styles.animatedImage,
          isVisible && styles.visible,
          className,
        )}
      />
    );
  },
);

AnimatedImage.displayName = "AnimatedImage";

export default AnimatedImage;
