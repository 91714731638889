import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInstance } from "swiper/types";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { useEffect, useRef, ReactNode, useCallback } from "react";

// Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import styles from "@/styles/bannerSwiper.module.scss";
import classNames from "classnames";

export interface BannerSwiperComponentProps {
  showNavigation?: boolean;
  showScrollbar?: boolean;
  showPagination?: boolean;
  className?: string;
  swiperItems: ReactNode[];
  isAutoPlay?: boolean;
  autoPlayDelay?: number;
  swiperKey: string;
  handleSlideChange?: () => void;
  handleSlidePrevTransitionEnd?: () => void;
  handleSlideNextTransitionEnd?: () => void;
  useFadeEffect?: boolean;
  isSingle?: boolean;
}

const BannerSwiperComponent = ({
  showNavigation = false,
  showScrollbar = false,
  showPagination = true,
  isSingle = false,
  className = "",
  swiperItems,
  isAutoPlay = true,
  autoPlayDelay = 2000,
  swiperKey,
  handleSlideChange,
  handleSlidePrevTransitionEnd,
  handleSlideNextTransitionEnd,
  useFadeEffect = false,
}: BannerSwiperComponentProps) => {
  const swiperRef = useRef<SwiperInstance | null>(null);

  const updateBullets = useCallback(() => {
    const swiperInstance = swiperRef.current;
    if (!swiperInstance) return;
    const bullets = document.querySelectorAll(
      `.${styles.customBullet} .${styles.bulletFill}`,
    );
    bullets.forEach((bullet, index) => {
      if (bullet instanceof HTMLElement) {
        if (index === swiperInstance.realIndex) {
          bullet.classList.add(styles.activeBullet);
        } else {
          bullet.classList.remove(styles.activeBullet);
        }
      }
    });
  }, []);

  const onSlideChange = useCallback(() => {
    updateBullets();
    handleSlideChange?.();
  }, [updateBullets, handleSlideChange]);

  const onPrevSlideTransitionEnd = useCallback(() => {
    handleSlidePrevTransitionEnd?.();
  }, [handleSlidePrevTransitionEnd]);

  const onNextSlideTransitionEnd = useCallback(() => {
    handleSlideNextTransitionEnd?.();
  }, [handleSlideNextTransitionEnd]);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (!swiperInstance) return;

    swiperInstance.on("slideChange", onSlideChange);
    swiperInstance.on("slidePrevTransitionStart", onPrevSlideTransitionEnd);
    swiperInstance.on("slideNextTransitionStart", onNextSlideTransitionEnd);

    // 초기 슬라이드 상태 업데이트
    updateBullets();

    return () => {
      if (!swiperInstance.el) return;
      swiperInstance.off("slideChange", onSlideChange);
      swiperInstance.off("slidePrevTransitionStart", onPrevSlideTransitionEnd);
      swiperInstance.off("slideNextTransitionStart", onNextSlideTransitionEnd);
    };
  }, [
    autoPlayDelay,
    onPrevSlideTransitionEnd,
    onNextSlideTransitionEnd,
    onSlideChange,
    updateBullets,
  ]);

  return (
    <Swiper
      onSwiper={(swiper: SwiperInstance) => {
        swiperRef.current = swiper;
      }}
      className={classNames(styles.swiperContainer, className)}
      spaceBetween={50}
      slidesPerView={isSingle ? 1 : "auto"}
      navigation={showNavigation}
      pagination={
        showPagination
          ? {
              clickable: true,
              renderBullet: (index: number, className: string) => {
                return `<span class="${className} ${styles.customBullet}"><span class="${styles.bulletFill}"></span></span>`;
              },
            }
          : false
      }
      scrollbar={showScrollbar ? { draggable: showScrollbar } : false}
      autoplay={
        isAutoPlay
          ? {
              delay: autoPlayDelay,
              pauseOnMouseEnter: false,
              reverseDirection: false,
            }
          : false
      }
      loop={true}
      {...(useFadeEffect
        ? { effect: "fade", fadeEffect: { crossFade: false } }
        : {})}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
    >
      {swiperItems.map((node, idx) => (
        <SwiperSlide key={`${swiperKey}_${idx}`}>
          {/* <div className="slide-content">{node}</div> */}
          {node}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerSwiperComponent;
