type ScrollBehavior = "auto" | "smooth";

export const scrollToTop = (behavior: ScrollBehavior = "auto") => {
  window.scrollTo({
    top: 0,
    behavior: behavior,
  });
};

export const scrollToTarget = (targetRef: React.RefObject<HTMLElement>) => {
  if (targetRef.current) {
    window.scrollTo({
      top: targetRef.current.offsetTop,
      behavior: "smooth",
    });
  }
};
