//로고이미지 import
import toastCamLogo from "@/assets/img/logo.svg";

//main 이미지 import
import krMainFuture01 from "@/assets/img/main/main_future_img01.png";
import krMainFutureM01 from "@/assets/img/main/main_future_img01_m.png";
import krMainFuture02 from "@/assets/img/main/main_future_img02.png";
import krMainFutureM02 from "@/assets/img/main/main_future_img02_m.png";
import krMainFuture03 from "@/assets/img/main/main_future_img03.png";
import krMainFutureM03 from "@/assets/img/main/main_future_img03_m.png";
import krMainCloudInstall01 from "@/assets/img/main/main_cloud_install_01.png";
import krMainCloudInstall02 from "@/assets/img/main/main_cloud_install_02.png";
import krMainCloudInstallM01 from "@/assets/img/main/main_cloud_install_01_m.png";
import krMainCloudInstallM02 from "@/assets/img/main/main_cloud_install_02_m.png";
import krMainCloudOption01 from "@/assets/img/main/main_cloud_option_01.png";
import krMainCloudOption02 from "@/assets/img/main/main_cloud_option_02.png";
import krMainCloudOption03 from "@/assets/img/main/main_cloud_option_03.png";
import krMainCloudOption04 from "@/assets/img/main/main_cloud_option_04.png";
import krMainCloudOptionCenter from "@/assets/img/main/main_cloud_option_center.png";
import securityPoint01Img01 from "@/assets/img/main/security_point01_img01.png";
import securityPoint01Img02 from "@/assets/img/main/security_point01_img02.png";
import securityPoint01Img03 from "@/assets/img/main/security_point01_img03.png";
import securityPoint01Img04 from "@/assets/img/main/security_point01_img04.png";
import securityPoint01MoImg01 from "@/assets/img/main/security_point01_m_img01.png";
import securityPoint01MoImg02 from "@/assets/img/main/security_point01_m_img02.png";
import securityPoint01MoImg03 from "@/assets/img/main/security_point01_m_img03.png";
import securityPoint01MoImg04 from "@/assets/img/main/security_point01_m_img04.png";
import securityPoint02Img01 from "@/assets/img/main/security_point02_img01.png";
import securityPoint02Img02 from "@/assets/img/main/security_point02_img02.png";
import securityPoint02Img03 from "@/assets/img/main/security_point02_img03.png";
import securityPoint02MoImg01 from "@/assets/img/main/security_point02_m_img01.png";
import securityPoint02MoImg02 from "@/assets/img/main/security_point02_m_img02.png";
import securityPoint02MoImg03 from "@/assets/img/main/security_point02_m_img03.png";
import mainCheckIcon01 from "@/assets/img/main/main_check_icon01.png";
import mainCheckIcon02 from "@/assets/img/main/main_check_icon02.png";
import mainCheckIcon03 from "@/assets/img/main/main_check_icon03.png";
import mainCheckIcon04 from "@/assets/img/main/main_check_icon04.png";
import mainMonitoringIcon01 from "@/assets/img/main/main_monitoring_icon01.png";
import mainMonitoringIcon02 from "@/assets/img/main/main_monitoring_icon02.png";
import mainMonitoringIcon03 from "@/assets/img/main/main_monitoring_icon03.png";
import mainCheckPcKr from "@/assets/img/main/main_check_pc_img.png";
import mainCheckMoKr from "@/assets/img/main/main_check_m_img.png";
import mainMonitoring from "@/assets/img/main/main_monitoring01.png";
import mainInstallIcon01 from "@/assets/img/main/main_install_icon01.png";
import mainInstallIcon02 from "@/assets/img/main/main_install_icon02.png";
import mainInstallIcon03 from "@/assets/img/main/main_install_icon03.png";
import krMainRecyclingImg01 from "@/assets/img/main/main_recycling_img01.png";
import krMainRecyclingImg02 from "@/assets/img/main/main_recycling_img02.png";
import krMainRecyclingImg03 from "@/assets/img/main/main_recycling_img03.png";
import krMainRecyclingImg01M from "@/assets/img/main/main_recycling_img01_m.png";
import krMainRecyclingImg02M from "@/assets/img/main/main_recycling_img02_m.png";
import krMainRecyclingImg03M from "@/assets/img/main/main_recycling_img03_m.png";
import deviceDome from "@/assets/img/main/main_device_dome.jpg";
import deviceDomeBlack from "@/assets/img/main/main_device_dome_bk.jpg";
import deviceBullet from "@/assets/img/main/main_device_bullet.jpg";
import deviceBulletBlack from "@/assets/img/main/main_device_bullet_bk.jpg";
import deviceV4 from "@/assets/img/main/main_device_v4.jpg";
import deviceGateway from "@/assets/img/main/main_device_gateway.jpg";
import mainGraphPC from "@/assets/img/main/main_graph_img_pc.jpg";
import caseIcon01 from "@/assets/img/main/main_case_icon01.png";
import caseIcon02 from "@/assets/img/main/main_case_icon02.png";
import caseIcon03 from "@/assets/img/main/main_case_icon03.png";
import customerIcon01 from "@/assets/img/main/main_customer_img01.png";
import customerIcon02 from "@/assets/img/main/main_customer_img02.png";
import customerIcon03 from "@/assets/img/main/main_customer_img03.png";

//jp이미지 import
import jpMainFuture01 from "@/assets/img/main/jp/main_future_img01_jp.png";
import jpMainFuture02 from "@/assets/img/main/jp/main_future_img02_jp.png";
import jpMainFutureM01 from "@/assets/img/main/jp/main_future_img01_m_jp.png";
import jpMainFutureM02 from "@/assets/img/main/jp/main_future_img02_m_jp.png";
import jpMainCloudInstall01 from "@/assets/img/main/jp/main_cloud_install_01_jp.png";
import jpMainCloudInstall01Mo from "@/assets/img/main/jp/main_cloud_install_01_m_jp.png";
import jpMainCloudInstall02 from "@/assets/img/main/jp/main_cloud_install_02_jp.png";
import jpMainCloudInstall02Mo from "@/assets/img/main/jp/main_cloud_install_02_m_jp.png";
import jpSecurityPoint01Img01 from "@/assets/img/main/jp/security_point01_img01_jp.png";
import jpSecurityPoint01Img02 from "@/assets/img/main/jp/security_point01_img02_jp.png";
import jpSecurityPoint01Img03 from "@/assets/img/main/jp/security_point01_img03_jp.png";
import jpSecurityPoint01Img04 from "@/assets/img/main/jp/security_point01_img04_jp.png";
import jpSecurityPoint01MoImg01 from "@/assets/img/main/jp/security_point01_m_img01_jp.png";
import jpSecurityPoint01MoImg02 from "@/assets/img/main/jp/security_point01_m_img02_jp.png";
import jpSecurityPoint01MoImg03 from "@/assets/img/main/jp/security_point01_m_img03_jp.png";
import jpSecurityPoint01MoImg04 from "@/assets/img/main/jp/security_point01_m_img04_jp.png";
import jpSecurityPoint02Img01 from "@/assets/img/main/jp/security_point02_img01_jp.png";
import jpSecurityPoint02Img02 from "@/assets/img/main/jp/security_point02_img02_jp.png";
import jpSecurityPoint02Img03 from "@/assets/img/main/jp/security_point02_img03_jp.png";
import jpSecurityPoint02MoImg01 from "@/assets/img/main/jp/security_point02_m_img01_jp.png";
import jpSecurityPoint02MoImg02 from "@/assets/img/main/jp/security_point02_m_img02_jp.png";
import jpSecurityPoint02MoImg03 from "@/assets/img/main/jp/security_point02_m_img03_jp.png";
import jpMainCloudOption01 from "@/assets/img/main/jp/main_cloud_option_01_jp.png";
import jpMainCloudOption02 from "@/assets/img/main/jp/main_cloud_option_02_jp.png";
import jpMainCloudOption03 from "@/assets/img/main/jp/main_cloud_option_03_jp.png";
import jpMainCloudOption04 from "@/assets/img/main/jp/main_cloud_option_04_jp.png";
import jpMainCloudOptionCenter from "@/assets/img/main/jp/main_cloud_option_center_jp.png";
import jpMainCheckPc from "@/assets/img/main/jp/main_check_pc_jp_img.png";
import jpMainCheckMo from "@/assets/img/main/jp/main_check_m_jp_img.png";
import jpMainRecyclingImg01 from "@/assets/img/main/jp/main_recycling_img01_jp.png";
import jpMainRecyclingImg02 from "@/assets/img/main/jp/main_recycling_img02_jp.png";
import jpMainRecyclingImg03 from "@/assets/img/main/jp/main_recycling_img03_jp.png";
import jpMainRecyclingImg01Mo from "@/assets/img/main/jp/main_recycling_img01_m_jp.png";
import jpMainRecyclingImg02Mo from "@/assets/img/main/jp/main_recycling_img02_m_jp.png";
import jpMainRecyclingImg03Mo from "@/assets/img/main/jp/main_recycling_img03_m_jp.png";

//h264-h265_device import
import h264Img01 from "@/assets/img/h264-h265-device/h264_product_img01.jpg";
import nightHouseDoor from "@/assets/img/h264-h265-device/product_night_house_door.jpg";
import nightDome from "@/assets/img/h264-h265-device/product_night_dome.png";
import constructionSite from "@/assets/img/h264-h265-device/product_construction_site.jpg";
import constructionBullet from "@/assets/img/h264-h265-device/product_construction_site_bullet.png";
import nightHouseDoorMo from "@/assets/img/h264-h265-device/product_night_house_door_mo.jpg";
import nightDomeMo from "@/assets/img/h264-h265-device/product_night_dome_mo.png";
import constructionSiteMo from "@/assets/img/h264-h265-device/product_construction_site_mo.jpg";
import constructionBulletMo from "@/assets/img/h264-h265-device/product_construction_site_bullet_mo.png";
import krCodecImg01 from "@/assets/img/h264-h265-device/h264_product_codec_img01.png";
import krCodecImg02 from "@/assets/img/h264-h265-device/h264_product_codec_img02.png";
import krCodecImg03 from "@/assets/img/h264-h265-device/h264_product_codec_img03.png";
import krCodecImg04 from "@/assets/img/h264-h265-device/h264_product_codec_img04.png";
import krCodecImg05 from "@/assets/img/h264-h265-device/h264_product_codec_img05.png";
import codecImg01Mo from "@/assets/img/h264-h265-device/h264_product_codec_img01_m.png";
import codecImg02Mo from "@/assets/img/h264-h265-device/h264_product_codec_img02_m.png";
import codecImg03Mo from "@/assets/img/h264-h265-device/h264_product_codec_img03_m.png";
import codecImg04Mo from "@/assets/img/h264-h265-device/h264_product_codec_img04_m.png";
import codecImg05Mo from "@/assets/img/h264-h265-device/h264_product_codec_img05_m.png";
import jpCodecImg01 from "@/assets/img/h264-h265-device/jp/product_con02_img01_jp.png";
import jpCodecImg04 from "@/assets/img/h264-h265-device/jp/product_con02_img04_jp.png";
import jpCodecImg05 from "@/assets/img/h264-h265-device/jp/product_con02_img05_jp.png";
import jpCodecImg01Mo from "@/assets/img/h264-h265-device/jp/product_con02_img01_m_jp.png";
import jpCodecImg04Mo from "@/assets/img/h264-h265-device/jp/product_con02_img04_m_jp.png";
import jpCodecImg05Mo from "@/assets/img/h264-h265-device/jp/product_con02_img05_m_jp.png";
import installImg01 from "@/assets/img/h264-h265-device/product_install_img01.png";
import krInstallImg02 from "@/assets/img/h264-h265-device/product_install_img02.png";
import installImg03 from "@/assets/img/h264-h265-device/product_install_img03.png";
import krInstallImg04 from "@/assets/img/h264-h265-device/product_install_img04.png";
import installImg05 from "@/assets/img/h264-h265-device/product_install_img05.png";
import krInstallImg02m from "@/assets/img/h264-h265-device/product_install_img02_m.png";
import krInstallImg04m from "@/assets/img/h264-h265-device/product_install_img04_m.png";
import jpInstallImg01 from "@/assets/img/h264-h265-device/jp/product_con06_img01_jp.png";
import jpInstallImg02 from "@/assets/img/h264-h265-device/jp/product_con06_img02_jp.png";
import jpInstallImg04 from "@/assets/img/h264-h265-device/jp/product_con06_img04_jp.png";
import jpInstallImg02Mo from "@/assets/img/h264-h265-device/jp/product_con06_img02_m_jp.png";
import jpInstallImg04Mo from "@/assets/img/h264-h265-device/jp/product_con06_img04_m_jp.png";
import domeImg from "@/assets/img/h264-h265-device/product_dome.jpg";
import bulletImg from "@/assets/img/h264-h265-device/product_bullet.png";
import storageImg01 from "@/assets/img/h264-h265-device/product_storage_img01.png";
import storageImg02 from "@/assets/img/h264-h265-device/product_storage_img02.png";
import storageImg03 from "@/assets/img/h264-h265-device/product_storage_img03.png";
import mStorageImg01 from "@/assets/img/h264-h265-device/product_storage_img01_m.png";
import mStorageImg02 from "@/assets/img/h264-h265-device/product_storage_img02_m.png";
import mStorageImg03 from "@/assets/img/h264-h265-device/product_storage_img03_m.png";
import jpStorageImg01 from "@/assets/img/h264-h265-device/jp/product_con05_img01_jp.png";
import mJpStorageImg01 from "@/assets/img/h264-h265-device/jp/product_con05_img01_m_jp.png";
import h265Con01 from "@/assets/img/h264-h265-device/h265_con02_img01.jpg";
import h265Con02 from "@/assets/img/h264-h265-device/h265_con02_img02.jpg";

//h265Detail import
import h265Service01 from "@/assets/img/h265-detail/h265_service_con01_img01.png";
import h265Service02 from "@/assets/img/h265-detail/h265_service_con01_img02.png";
import h265GraphImg from "@/assets/img/h265-detail/sub_graph_img_pc.jpg";
import h265DeviceDome from "@/assets/img/h265-detail/device_dome.jpg";
import h265DeviceBullet from "@/assets/img/h265-detail/device_bullet.jpg";

//파트너사 import
import partnerFlow from "@/assets/img/partner/partner_flow.png";
import partnerFlow2 from "@/assets/img/partner/partner_flow2.png";
import partnerFlowKr from "@/assets/img/partner/partner_flow_kr.png";
import partnerFlowKr2 from "@/assets/img/partner/partner_flow2_kr.png";
import partnerBenefit3 from "@/assets/img/partner/partner_benefit3.jpg";
import partnerBenefit4 from "@/assets/img/partner/partner_benefit4.jpg";
import partnerCaseArtisee from "@/assets/img/partner/partner_case_artisee.jpg";
import partnerArtiseeLogo from "@/assets/img/partner/partner_logo_artisee.png";
import partnerCaseOutdoor from "@/assets/img/partner/partner_case_outdoor.jpg";
import partnerOutdoorLogo from "@/assets/img/partner/partner_logo_outdoor.png";
import partnerCaseChicken from "@/assets/img/partner/partner_case_60gye.jpg";
import partnerChickenLogo from "@/assets/img/partner/partner_logo_chicken.png";

//V4 import

import v4AiCamera from "@/assets/img/v4/v4_con01_img01.png";
import section02LineImg from "@/assets/img/v4/v4_con02_line.png";
import section02Img01 from "@/assets/img/v4/v4_con02_img01.png";
import section02Img02 from "@/assets/img/v4/v4_con02_img02.png";
import section02Img03 from "@/assets/img/v4/v4_con02_img03.png";
import section02Img04 from "@/assets/img/v4/v4_con02_img04.png";
import section02Img01Jp from "@/assets/img/v4/v4_con02_img01_jp.png";
import section02Img02Jp from "@/assets/img/v4/v4_con02_img02_jp.png";
import section02Img03Jp from "@/assets/img/v4/v4_con02_img03_jp.png";
import section02Img04Jp from "@/assets/img/v4/v4_con02_img04_jp.png";
import section02LineMoImg from "@/assets/img/v4/v4_con02_line_m.png";
import section02MoImg01 from "@/assets/img/v4/v4_con02_img01_m.png";
import section02MoImg02 from "@/assets/img/v4/v4_con02_img02_m.png";
import section02MoImg03 from "@/assets/img/v4/v4_con02_img03_m.png";
import section02MoImg04 from "@/assets/img/v4/v4_con02_img04_m.png";
import section02MoImg01JP from "@/assets/img/v4/v4_con02_img01_m_jp.png";
import section02MoImg02JP from "@/assets/img/v4/v4_con02_img02_m_jp.png";
import section02MoImg03JP from "@/assets/img/v4/v4_con02_img03_m_jp.png";
import section02MoImg04JP from "@/assets/img/v4/v4_con02_img04_m_jp.png";
import section05Img from "@/assets/img/v4/v4_con05_img01.png";
import section05ImgJp from "@/assets/img/v4/v4_con05_img01_jp.png";
import section06Img01 from "@/assets/img/v4/v4_con06_img01.png";
import section06Img02 from "@/assets/img/v4/v4_con06_img02.png";
import section06Img03 from "@/assets/img/v4/v4_con06_img03.png";
import section06Img04 from "@/assets/img/v4/v4_con06_img04.png";
import section06MoImg01 from "@/assets/img/v4/v4_con06_img01_m.png";
import section06MoImg02 from "@/assets/img/v4/v4_con06_img02_m.png";
import section06MoImg03 from "@/assets/img/v4/v4_con06_img03_m.png";
import section06Img01Jp from "@/assets/img/v4/v4_con06_img01_jp.png";
import section06Img02Jp from "@/assets/img/v4/v4_con06_img02_jp.png";
import section06Img03Jp from "@/assets/img/v4/v4_con06_img03_jp.png";
import section06Img04Jp from "@/assets/img/v4/v4_con06_img04_jp.png";
import section06MoImg01Jp from "@/assets/img/v4/v4_con06_img01_m_jp.png";
import section06MoImg02Jp from "@/assets/img/v4/v4_con06_img02_m_jp.png";
import section06MoImg03Jp from "@/assets/img/v4/v4_con06_img03_m_jp.png";
import section06MoImg04Jp from "@/assets/img/v4/v4_con06_img04_m_jp.png";

/**공통 이미지 */
export const commonImage = {
  toastCamLogo,
};

/** 메인 이미지 */
export const mainImage = {
  krMainFuture01,
  krMainFutureM01,
  krMainFuture02,
  krMainFutureM02,
  krMainFuture03,
  krMainFutureM03,
  krMainCloudInstall01,
  krMainCloudInstall02,
  krMainCloudInstallM01,
  krMainCloudInstallM02,
  krMainCloudOption01,
  krMainCloudOption02,
  krMainCloudOption03,
  krMainCloudOption04,
  krMainCloudOptionCenter,
  securityPoint01Img01,
  securityPoint01Img02,
  securityPoint01Img03,
  securityPoint01Img04,
  securityPoint01MoImg01,
  securityPoint01MoImg02,
  securityPoint01MoImg03,
  securityPoint01MoImg04,
  securityPoint02Img01,
  securityPoint02Img02,
  securityPoint02Img03,
  securityPoint02MoImg01,
  securityPoint02MoImg02,
  securityPoint02MoImg03,
  mainCheckIcon01,
  mainCheckIcon02,
  mainCheckIcon03,
  mainCheckIcon04,
  mainMonitoringIcon01,
  mainMonitoringIcon02,
  mainMonitoringIcon03,
  mainCheckPcKr,
  mainCheckMoKr,
  mainMonitoring,
  mainInstallIcon01,
  mainInstallIcon02,
  mainInstallIcon03,
  krMainRecyclingImg01,
  krMainRecyclingImg02,
  krMainRecyclingImg03,
  krMainRecyclingImg01M,
  krMainRecyclingImg02M,
  krMainRecyclingImg03M,
  deviceDome,
  deviceDomeBlack,
  deviceBullet,
  deviceBulletBlack,
  deviceV4,
  deviceGateway,
  mainGraphPC,
  caseIcon01,
  caseIcon02,
  caseIcon03,
  customerIcon01,
  customerIcon02,
  customerIcon03,
};

/** jp 메인 이미지 */
export const jpMainImage = {
  jpMainFuture01,
  jpMainFuture02,
  jpMainFutureM01,
  jpMainFutureM02,
  jpMainCloudInstall01,
  jpMainCloudInstall01Mo,
  jpMainCloudInstall02,
  jpMainCloudInstall02Mo,
  jpSecurityPoint01Img01,
  jpSecurityPoint01Img02,
  jpSecurityPoint01Img03,
  jpSecurityPoint01Img04,
  jpSecurityPoint01MoImg01,
  jpSecurityPoint01MoImg02,
  jpSecurityPoint01MoImg03,
  jpSecurityPoint01MoImg04,
  jpSecurityPoint02Img01,
  jpSecurityPoint02Img02,
  jpSecurityPoint02Img03,
  jpSecurityPoint02MoImg01,
  jpSecurityPoint02MoImg02,
  jpSecurityPoint02MoImg03,
  jpMainCloudOption01,
  jpMainCloudOption02,
  jpMainCloudOption03,
  jpMainCloudOption04,
  jpMainCloudOptionCenter,
  jpMainCheckPc,
  jpMainCheckMo,
  jpMainRecyclingImg01,
  jpMainRecyclingImg02,
  jpMainRecyclingImg03,
  jpMainRecyclingImg01Mo,
  jpMainRecyclingImg02Mo,
  jpMainRecyclingImg03Mo,
};

/**H264/H265 이미지 */
export const h264H265Image = {
  h264Img01,
  nightHouseDoor,
  nightDome,
  constructionSite,
  constructionBullet,
  nightHouseDoorMo,
  nightDomeMo,
  constructionSiteMo,
  constructionBulletMo,
  krCodecImg01,
  krCodecImg02,
  krCodecImg03,
  krCodecImg04,
  krCodecImg05,
  codecImg01Mo,
  codecImg02Mo,
  codecImg03Mo,
  codecImg04Mo,
  codecImg05Mo,
  jpCodecImg01,
  jpCodecImg04,
  jpCodecImg05,
  jpCodecImg01Mo,
  jpCodecImg04Mo,
  jpCodecImg05Mo,
  installImg01,
  krInstallImg02,
  installImg03,
  krInstallImg04,
  installImg05,
  krInstallImg02m,
  krInstallImg04m,
  jpInstallImg01,
  jpInstallImg02,
  jpInstallImg04,
  jpInstallImg02Mo,
  jpInstallImg04Mo,
  domeImg,
  bulletImg,
  storageImg01,
  storageImg02,
  storageImg03,
  mStorageImg01,
  mStorageImg02,
  mStorageImg03,
  jpStorageImg01,
  mJpStorageImg01,
  h265Con01,
  h265Con02,
};

/** H265 상세 이미지 */
export const h265Detail = {
  h265Service01,
  h265Service02,
  h265GraphImg,
  h265DeviceDome,
  h265DeviceBullet,
};

/** 파트너사 이미지 */
export const partnerImage = {
  partnerFlow,
  partnerFlow2,
  partnerFlowKr,
  partnerFlowKr2,
  partnerBenefit3,
  partnerBenefit4,
  partnerCaseArtisee,
  partnerArtiseeLogo,
  partnerCaseOutdoor,
  partnerOutdoorLogo,
  partnerCaseChicken,
  partnerChickenLogo,
};

/** V4 상세 이미지 */
export const v4Image = {
  v4AiCamera,
  section02LineImg,
  section02Img01,
  section02Img02,
  section02Img03,
  section02Img04,
  section02Img01Jp,
  section02Img02Jp,
  section02Img03Jp,
  section02Img04Jp,
  section02LineMoImg,
  section02MoImg01,
  section02MoImg02,
  section02MoImg03,
  section02MoImg04,
  section02MoImg01JP,
  section02MoImg02JP,
  section02MoImg03JP,
  section02MoImg04JP,
  section05Img,
  section05ImgJp,
  section06Img01,
  section06Img02,
  section06Img03,
  section06Img04,
  section06MoImg01,
  section06MoImg02,
  section06MoImg03,
  section06Img01Jp,
  section06Img02Jp,
  section06Img03Jp,
  section06Img04Jp,
  section06MoImg01Jp,
  section06MoImg02Jp,
  section06MoImg03Jp,
  section06MoImg04Jp,
};
