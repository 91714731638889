import useViewportStore from "@/store/viewport";
import { useEffect } from "react";

const useViewport = () => {
  const { setIsMobile, setViewPortHeight, setViewPortWidth } = useViewportStore(
    (state) => state,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setViewPortHeight(window.innerHeight);
      setViewPortWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [setIsMobile, setViewPortHeight, setViewPortWidth]);
};

export default useViewport;
