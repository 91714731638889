import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationJP from "./locale-jp.json";
import translationKR from "./locale-kr.json";
import partnerJP from "./partner.locale-jp.json";
import partnerKR from "./partner.locale-kr.json";

const resources = {
  jp: {
    translation: translationJP,
    partner: partnerJP,
  },
  ko: {
    translation: translationKR,
    partner: partnerKR,
  },
};

// 브라우저 언어 설정 감지
const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.languages[0];
  if (lang.startsWith("ko")) {
    return "ko";
  } else if (lang.startsWith("jp") || lang.startsWith("ja")) {
    return "jp";
  }
  return "ko";
};

i18n.use(initReactI18next).init({
  resources,
  ns: ["translation", "partner"],
  lng: getBrowserLanguage(),
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
