import { useEffect } from "react";
import useLanguageStore from "@/store/language";

const useBrowserLanguage = () => {
  const { setLanguage } = useLanguageStore();

  useEffect(() => {
    const getBrowserLanguage = () => {
      const lang = navigator.language || navigator.languages[0];
      if (lang.startsWith("ko")) {
        return "ko";
      } else if (lang.startsWith("jp") || lang.startsWith("ja")) {
        return "jp";
      }
      return "ko"; // 기본값을 'ko'로 설정합니다.
    };

    const browserLanguage = getBrowserLanguage();
    document.documentElement.lang = browserLanguage;
    setLanguage(browserLanguage);
  }, [setLanguage]);
};

export default useBrowserLanguage;
