import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export interface MetaTagProps {
  title?: string;
  description?: string;
  keywords?: string;
  imgSrc?: string;
  url?: string;
}

const MetaTag = ({
  title = "",
  description = "TOASTCAM COMPANY website",
  imgSrc,
  keywords,
  url,
}: MetaTagProps) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      {imgSrc && <meta property="og:image" content={imgSrc} />}{" "}
      {url && <meta property="og:url" content={url} />}
    </Helmet>
  );
};

export default MetaTag;
