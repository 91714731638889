import {
  ReactNode,
  useEffect,
  useRef,
  useState,
  ElementType,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import styles from "@/styles/animatedTypography.module.scss";
import classNames from "classnames";

export interface AnimatedTypographyProps {
  children: ReactNode;
  tag?: ElementType;
  animationSpeed?: number;
  /** millisecond */
  animationDelay?: number;
  initialTranslate3d?: InitialTranslate3dProps;
  className?: string;
  id?: string;
}

export interface InitialTranslate3dProps {
  x?: string;
  y?: string;
  z?: string;
}

const AnimatedTypography = forwardRef<HTMLElement, AnimatedTypographyProps>(
  (
    {
      children,
      tag: Tag = "div",
      animationSpeed = 0.8,
      animationDelay = 0,
      className = "",
      initialTranslate3d = {
        x: "0",
        y: "30px",
        z: "0",
      },
      id = "",
    },
    ref,
  ) => {
    const typographyRef = useRef<HTMLElement | SVGElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const { x, y, z } = initialTranslate3d;
    const translate3D = `translate3d(${x}, ${y}, ${z})`;

    // Forward ref to parent component
    useImperativeHandle(ref, () => typographyRef.current as HTMLElement);

    const handleScroll = useCallback(() => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos;

      setPrevScrollPos(currentScrollPos);

      if (isScrollingDown) {
        if (typographyRef.current && observerRef.current) {
          observerRef.current.observe(typographyRef.current);
        }
      }
    }, [prevScrollPos]);

    useEffect(() => {
      const currentTypographyRef = typographyRef.current;

      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else if (entry.boundingClientRect.top > 0) {
              setIsVisible(false);
            }
          });
        },
        {
          threshold: 0.1,
        },
      );

      if (typographyRef.current && observerRef.current) {
        observerRef.current.observe(typographyRef.current);
      }

      return () => {
        if (observerRef.current && currentTypographyRef) {
          observerRef.current.unobserve(currentTypographyRef);
        }
      };
    }, []);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [prevScrollPos, handleScroll]);

    useEffect(() => {
      if (typographyRef.current) {
        (typographyRef.current as HTMLElement).style.transitionDuration =
          `${animationSpeed}s`;
        (typographyRef.current as HTMLElement).style.transitionDelay =
          `${animationDelay}ms`;
        (typographyRef.current as HTMLElement).style.transform = isVisible
          ? "none"
          : translate3D;
      }
    }, [isVisible, animationSpeed, animationDelay, translate3D]);

    return (
      <Tag
        id={id}
        ref={typographyRef}
        className={classNames(
          styles.animatedTypography,
          isVisible && styles.visible,
          className,
        )}
      >
        {children}
      </Tag>
    );
  },
);

export default AnimatedTypography;
