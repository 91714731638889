import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MetaTag from "./MetaTag";
import styles from "@/styles/layout.module.scss";
import { useTranslation } from "react-i18next";

const Layout = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const noHeaderPath = ["/partner"];
  const noFooterPath = [""];
  const topFixedHeaderPath = ["/"];

  const showHeader = !noHeaderPath.includes(location.pathname);
  const showFooter = !noFooterPath.includes(location.pathname);
  const isTopFixedHeader = !topFixedHeaderPath.includes(location.pathname);

  return (
    <div className={styles.wrap}>
      <MetaTag
        title={t("META_TITLE")}
        description={t("META_DESCRIPTION")}
      ></MetaTag>
      {showHeader && <Header isFixedTop={isTopFixedHeader} />}
      <main className={styles.mainContainer}>
        <Outlet />
      </main>
      {showFooter && <Footer />}
    </div>
  );
};
export default Layout;
